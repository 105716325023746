import { cn } from '@/utils/cn';
import type { FC, ReactNode } from 'react';

interface FooterProps {
  actionButtons: ReactNode;
  content?: ReactNode;
}

const Footer: FC<FooterProps> = ({ actionButtons, content }) => {
  return (
    <footer
      className={cn(
        'fixed bottom-0 left-0 right-0 flex w-full justify-center bg-white text-primary',
        { 'border-t': actionButtons }
      )}
    >
      <div
        id="footer-wrapper"
        className="flex w-full flex-col laptop:max-w-tablet laptop:flex-row laptop:items-center desktop:max-w-laptop"
      >
        {content && (
          <div
            id="footer-content"
            className="border-b border-neutral-light px-5 py-3 laptop:border-none"
          >
            {content}
          </div>
        )}
        {actionButtons && (
          <div
            id="footer-buttons"
            className="flex w-full gap-2 px-5 py-3 laptop:justify-end"
          >
            {actionButtons}
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
