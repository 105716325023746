import type { Flow } from '@/types/booking';
import { useBooking } from '@/hooks/useBooking';
import { useEffect, useState } from 'react';
import type {
  DecoratedTravelPassDetails,
  PartitionedTravelPassDetails,
  TravelPassDetails,
} from '@/types/manage';
import { fetchTravelPassDetails } from '@/features/manage/manageService';
import type {
  BookingDTO,
  MultiRideAccountDTO,
  ReductionCardAccountDTO,
  TravelPassAccountDTO,
} from '@/types/dto';

export const useBookingNonTripOffers = (
  flow: Flow
): Array<
  DecoratedTravelPassDetails<TravelPassAccountDTO | MultiRideAccountDTO>
> => {
  const booking = useBooking(flow);

  const [nonTripOffers, setNonTripOffers] =
    useState<PartitionedTravelPassDetails>({
      multiRide: [],
      travelPass: [],
      reductionCard: [], // TODO: Handle these cards once there's design for them
    });

  useEffect(() => {
    async function getDetails() {
      setNonTripOffers(await getPreparedNonTripOffers(booking));
    }
    getDetails();
  }, [booking]);

  return [...nonTripOffers.travelPass, ...nonTripOffers.multiRide];
};

const getPreparedNonTripOffers = async (
  booking: BookingDTO | null
): Promise<PartitionedTravelPassDetails> => {
  const mappedNonTripOffers = prepareNonTripOffers(booking);
  const details = await fetchTravelPassDetails(mappedNonTripOffers);

  return { ...partitionByAccountType(details) };
};

const prepareNonTripOffers = (
  booking: BookingDTO | null
): Array<TravelPassDetails> => {
  const result = [];

  for (const nonTripOffer of booking?.nonTripOffers || []) {
    for (const admission of nonTripOffer.admissions || []) {
      for (const fulfillment of admission.fulfillments || []) {
        result.push({
          productDescriptiveTexts: admission.productDescriptiveTexts,
          productDescription: admission.productDescription,
          exchangeable: admission.exchangeable,
          refundable: admission.refundable,
          controlNumber: fulfillment.controlNumber,
          issuer: fulfillment.issuer,
          price: admission.price,
          bookingPassengers: booking?.passengers ?? [],
          admissionPassengerIds: admission.passengerIds,
          validFrom: admission.validFrom,
        });
      }
    }
  }

  return result;
};

const partitionByAccountType = (
  details: Array<DecoratedTravelPassDetails>
): PartitionedTravelPassDetails => {
  const multiRide = [];
  const travelPass = [];
  const reductionCard = [];

  for (const item of details) {
    switch (item.travelAccount?.objectType) {
      case 'MultiRideAccount':
        multiRide.push(
          item as unknown as DecoratedTravelPassDetails<MultiRideAccountDTO>
        );
        break;
      case 'TravelPassAccount':
        travelPass.push(
          item as unknown as DecoratedTravelPassDetails<TravelPassAccountDTO>
        );
        break;
      case 'ReductionCardAccount':
        reductionCard.push(
          item as unknown as DecoratedTravelPassDetails<ReductionCardAccountDTO>
        );
        break;
      default:
        break;
    }
  }

  return {
    multiRide,
    travelPass,
    reductionCard,
  };
};
