import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '@/utils/cn';
import { Icons } from '@/components/icons';

interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  indeterminate?: boolean;
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, indeterminate, checked, children, ...props }, ref) => {
  return (
    <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          'peer h-4 w-4 shrink-0 rounded-sm border border-neutral ring-offset-white',
          'data-[state=checked]:bg-primary data-[state=checked]:text-white',
          'hover:border-primary-dark hover:bg-primary-pale',
          'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-secondary focus-visible:ring-offset-2',
          'disabled:cursor-not-allowed disabled:border-neutral-light disabled:bg-greyscale-100',
          'disabled:hover:bg-greyscale-100',
          'data-[state=checked]:hover:bg-primary-medium data-[state=checked]:disabled:border-neutral-light data-[state=checked]:disabled:bg-neutral data-[state=checked]:disabled:text-neutral-light',
          {
            'data-[state=indeterminate]:bg-primary data-[state=indeterminate]:text-white':
              indeterminate,
            'data-[state=indeterminate]:disabled:border-neutral-light data-[state=indeterminate]:disabled:bg-neutral data-[state=indeterminate]:disabled:text-neutral-light':
              indeterminate,
          },
          className
        )}
        checked={indeterminate ? 'indeterminate' : checked}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={cn('flex items-center justify-center text-current')}
        >
          {indeterminate ? (
            <Icons.minus className="h-3 w-3" />
          ) : (
            <Icons.check className="h-3 w-3" />
          )}
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {children && (
        <label
          htmlFor={props.id}
          className="ml-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </label>
      )}
    </div>
  );
});

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
