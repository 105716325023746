import type { BookedAncillaryDTO, BookingDTO } from '@/types/dto';

type PassengerIdsByAncillaryId = Record<
  NonNullable<BookedAncillaryDTO['id']>,
  Array<string>
>;

export const useBookingPassengerIdsByAncillaryId = (
  booking: BookingDTO | null
) => {
  const passengerIdsByAncillaryId: PassengerIdsByAncillaryId = {};

  booking?.bookedTrips?.forEach(({ bookedOffers }) =>
    bookedOffers?.forEach(({ admissions }) =>
      admissions?.forEach(({ passengerIds, ancillaries }) =>
        ancillaries?.forEach(({ id }) => {
          if (!id) {
            return;
          }

          passengerIdsByAncillaryId[id] = [
            ...(passengerIdsByAncillaryId[id] || []),
            ...(passengerIds || []),
          ];
        })
      )
    )
  );

  return passengerIdsByAncillaryId;
};
