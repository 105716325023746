import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { formatDay, formatYear } from '@/utils/date-time';
import type { TravelPassAccountDTO } from '@/types/dto';

interface TravelPassAccountValidityProps {
  travelAccount: TravelPassAccountDTO;
}

const TravelPassAccountValidity: FC<TravelPassAccountValidityProps> = ({
  travelAccount,
}) => {
  return (
    <div className="flex w-full flex-col gap-1">
      <div
        className="flex items-center gap-2"
        data-testid="travel-pass-valid-from-label"
      >
        <Typography
          variant="body2"
          className="w-[70px] text-nowrap text-neutral"
        >
          <TransText i18nKey="validFrom" />:
        </Typography>
        <div className="flex gap-1" data-testid="travel-pass-valid-from-value">
          <Typography variant="body2-bold" className="text-nowrap">
            {formatDay(travelAccount.validFrom)},
          </Typography>
          <Typography variant="body2">
            {formatYear(travelAccount.validFrom)}
          </Typography>
        </div>
      </div>

      <div
        className="flex items-center gap-2"
        data-testid="travel-pass-valid-to-label"
      >
        <Typography
          variant="body2"
          className="w-[70px] text-nowrap text-neutral"
        >
          <TransText i18nKey="validTo" />:
        </Typography>
        <div className="flex gap-1" data-testid="travel-pass-valid-to-value">
          <Typography variant="body2-bold" className="text-nowrap">
            {formatDay(travelAccount.validUntil)},
          </Typography>
          <Typography variant="body2">
            {formatYear(travelAccount.validUntil)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TravelPassAccountValidity;
