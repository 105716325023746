import type { BookingDTO } from '@/types/dto';

export const getFulfillmentIds = (booking: BookingDTO): string[] => {
  return (
    booking.bookedTrips?.flatMap(
      (trip) =>
        trip.bookedOffers?.flatMap(
          (offer) =>
            offer.admissions?.flatMap(
              (admission) =>
                admission.fulfillments
                  ?.map((fulfillment) => fulfillment.id)
                  .filter((id): id is string => id !== undefined) ?? []
            ) ?? []
        ) ?? []
    ) ?? []
  ).filter((id): id is string => id !== undefined);
};
