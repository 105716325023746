import type { FC } from 'react';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { getLanguage } from '@/i18n';
import _toUpper from 'lodash/toUpper';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/primitives/Dialog';
import { TransText } from '@/i18n/trans/text';
import LanguageSelectContent from '@/components/header/language-select/LanguageSelectContent';

interface MobileLanguageSelectProps {}

const MobileLanguageSelect: FC<MobileLanguageSelectProps> = () => {
  const currentLanguage = getLanguage();

  return (
    <Dialog>
      <DialogTrigger
        className="flex flex-row items-center gap-2 px-0"
        data-testid="language-select-trigger"
      >
        <Icons.globe />
        <Typography variant="button">{_toUpper(currentLanguage)}</Typography>
      </DialogTrigger>
      <DialogContent className="gap-0 p-0">
        <DialogHeader className="border-none text-left">
          <DialogTitle>
            <TransText i18nKey="selectLanguage" />
          </DialogTitle>
          <DialogDescription hidden>
            <TransText i18nKey="selectLanguage" />
          </DialogDescription>
        </DialogHeader>
        <LanguageSelectContent />
      </DialogContent>
    </Dialog>
  );
};

export default MobileLanguageSelect;
