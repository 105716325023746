import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import I18NextLocizeBackend from 'i18next-locize-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';

export const NAMESPACE = 'webshop-booking-engine';

export const localeConfigurations: Record<
  string,
  {
    label: string;
    dateFormat: string;
    timeFormat: string;
  }
> = {
  en: { label: 'English', dateFormat: 'dd/MM/yyyy', timeFormat: 'HH:mm' },
  it: { label: 'Italiano', dateFormat: 'dd/mm/yyyy', timeFormat: 'HH:mm' },
};

i18n
  .use(I18NextLocizeBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng: 'en',
    supportedLngs: Object.keys(localeConfigurations),
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: NAMESPACE,
    ns: NAMESPACE,
    // debug: true,
    initImmediate: false,
    backend: {
      projectId: '42618d3a-3808-4dd3-b18b-ee0649d90021',
    },
  });

export const getLanguage = () =>
  i18n.language ?? window.localStorage.i18nextLng ?? 'en';

export default i18n;
