import type { FC } from 'react';
import ContentCard from '@/components/ContentCard';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import useBookingTotalPrice from '@/hooks/useBookingTotalPrice';
import SummaryContent from '@/components/booking-summary/SummaryContent';
import type { BookingSummaryData } from '@/hooks/useBookingSummaryData';
import type { Flow } from '@/types/booking';
import { useBooking } from '@/hooks/useBooking';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';

interface BookingSummaryProps extends BookingSummaryData {
  flow: Flow;
}

const RegularBookingSummary: FC<BookingSummaryProps> = (props) => {
  const booking = useBooking(props.flow);
  const amount = useBookingTotalPrice(booking);
  const currency = useSelector(currencySelector);

  return (
    <ContentCard className={{ root: 'w-[320px] text-dark' }}>
      <SummaryContent {...props} />
      <Divider className="border-t-neutral-light" />
      <div className="flex flex-row justify-between p-4">
        <div className="flex gap-2">
          <Icons.shoppingCart className="text-primary" />
          <Typography variant="body1-bold">
            <TransText i18nKey="totalPrice" />
          </Typography>
        </div>
        <Typography variant="subtitle" className="text-primary">
          {amount}
          {currency.symbol}
        </Typography>
      </div>
    </ContentCard>
  );
};

export default RegularBookingSummary;
