import { type ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useDispatch, useSelector } from '@/store/utils';
import ContentCard from '@/components/ContentCard';
import { Typography } from '@/components/primitives/Typography';
import {
  cancelPurchaseFlowBooking,
  clearPurchaseFlowBooking,
  getPurchaseFlowBookingAdditionalOffersSearch,
  payWithExternalPayment,
  resetPurchase,
  setActiveStep,
  updatePurchaseFlowBookingPassengers,
  updatePurchaseFlowBookingPurchaser,
} from '@/features/purchase/purchaseActions';
import Divider from '@/components/primitives/Divider';
import type { TransTextKeys } from '@/i18n/trans/text';
import { TransText } from '@/i18n/trans/text';
import AncillaryList from '@/components/purchase/checkout/ancillaries/AncillaryList';
import PurchaserForm from '@/components/purchase/checkout/purchaser/PurchaserForm';
import { Button } from '@/components/primitives/Button';
import BookingExpirationTimer from '@/components/purchase/checkout/expiration-timer/BookingExpirationTimer';
import { Icons } from '@/components/icons';
import PassengerList from '@/components/purchase/checkout/passengers/PassengerList';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  type PassengerValues,
  type PaymentMethodValues,
  type PurchaserValues,
  type ConsentsValues,
  createPassengerSchema,
  createPurchaserSchema,
  createPaymentMethodSchema,
  createConsentsSchema,
} from '@/utils/zod/schema';
import Footer from '@/components/Footer';
import { Loadable } from '@/components/Loadable';
import { checkoutLoadingSelector } from '@/features/loading/loadingSelectors';
import PaymentMethod from '@/components/purchase/checkout/payment-method/PaymentMethod';
import useBookingTotalPrice from '@/hooks/useBookingTotalPrice';
import { STEP } from '@/utils/consts';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { TransAlert } from '@/i18n/trans/alert';
import type { PassengerDTO } from '@/types/dto';
import useBookingSummaryData from '@/hooks/useBookingSummaryData';
import { useNavigate } from 'react-router-dom';
import RegularBookingSummary from '@/components/booking-summary/RegularBookingSummary';
import Consents from '@/components/purchase/checkout/Consents';
import { persistor } from '@/store';
import {
  purchaseFlowBookingPassengersSelector,
  purchaseFlowBookingSelector,
} from '@/features/purchase/purchaseSelectors';
import { Flow } from '@/types/booking';
import { useTranslation } from 'react-i18next';
import { currencySelector } from '@/features/configuration/configurationSelector';
import MobileBookingSummary from '@/components/booking-summary/MobileBookingSummary';
import { STORAGE_KEYS, useSessionStorage } from '@/hooks/useSessionStorage';
import type { CheckoutSuccessSessionStorageData } from '@/types/session-storage';
import { getFulfillmentIds } from '@/utils/admission';
import ConfirmModal from '@/components/ConfirmModal';

const Checkout = () => {
  const dispatch = useDispatch();
  const loading = useSelector(checkoutLoadingSelector);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const booking = useSelector(purchaseFlowBookingSelector);
  const passengers = useSelector(purchaseFlowBookingPassengersSelector);
  const amount = useBookingTotalPrice(booking);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currency = useSelector(currencySelector);
  const { setValue: setCheckoutSuccessSessionStorage } =
    useSessionStorage<CheckoutSuccessSessionStorageData>(
      STORAGE_KEYS.CHECKOUT_SUCCESS_DATA
    );

  useEffect(() => {
    if (booking?.id) {
      dispatch(getPurchaseFlowBookingAdditionalOffersSearch(booking.id));
    }
  }, [booking?.id, dispatch]);

  const passengerListForm = useForm<{
    passengers: Array<PassengerValues>;
  }>({
    resolver: zodResolver(
      z.object({ passengers: z.array(createPassengerSchema(t)) })
    ),
    defaultValues: {
      passengers: passengers?.map(
        ({
          id,
          externalReference,
          firstName,
          lastName,
          contactInformation,
          age,
        }) => ({
          id,
          externalReference,
          firstName: firstName?.value || '',
          lastName: lastName?.value || '',
          email: contactInformation?.emailAddress?.value || '',
          phone: { number: contactInformation?.phoneNumber?.value || '' },
          age: age || null,
        })
      ),
    },
  });

  const formPassengers = passengerListForm.watch('passengers');

  const purchaserForm = useForm<PurchaserValues>({
    resolver: zodResolver(createPurchaserSchema(t)),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: { number: '' },
    },
  });

  const paymentMethodForm = useForm<PaymentMethodValues>({
    resolver: zodResolver(createPaymentMethodSchema(t)),
    defaultValues: {
      id: 'SALES_POINT_PAYMENT_TYPE.EXTERNAL_3RD_PARTY',
    },
  });

  const consentsForm = useForm<ConsentsValues>({
    resolver: zodResolver(createConsentsSchema(t)),
    defaultValues: {
      consents: [],
    },
  });

  const getSections = useCallback((): Array<[TransTextKeys, ReactNode]> => {
    if ((booking?.bookedTrips?.length || 0) > 0) {
      return [
        ['passengers', <PassengerList passengerListForm={passengerListForm} />],
        [
          'ancillaries',
          <AncillaryList passengerListForm={passengerListForm} />,
        ],
        [
          'payer',
          <PurchaserForm
            purchaserForm={purchaserForm}
            passengerListForm={passengerListForm}
          />,
        ],
        [
          'paymentMethod',
          <PaymentMethod paymentMethodForm={paymentMethodForm} />,
        ],
      ];
    }

    return [
      ['passengers', <PassengerList passengerListForm={passengerListForm} />],
      [
        'payer',
        <PurchaserForm
          purchaserForm={purchaserForm}
          passengerListForm={passengerListForm}
        />,
      ],
      [
        'paymentMethod',
        <PaymentMethod paymentMethodForm={paymentMethodForm} />,
      ],
    ];
  }, [
    booking?.bookedTrips?.length,
    passengerListForm,
    paymentMethodForm,
    purchaserForm,
  ]);

  const handlePayment = useCallback(async () => {
    const typeId = paymentMethodForm.getValues()['id'];

    if (!currency.name) {
      return toast.error(<TransAlert i18nKey="undefinedCurrency" />);
    }

    dispatch(
      payWithExternalPayment({
        bookingId: booking?.id,
        paidAmount: {
          amount,
          currency: currency.name,
        },
        transactionId: v4(),
        typeId,
      })
    );
  }, [paymentMethodForm, booking?.id, dispatch, amount, currency]);

  const handlePayClicked = useCallback(async () => {
    const passengersValid = await passengerListForm.trigger();
    const purchaserValid = await purchaserForm.trigger();
    const consentsAgreedWith = await consentsForm.trigger();
    const payerEmail = purchaserForm.getValues().email;

    if (!consentsAgreedWith)
      toast.error(<TransAlert i18nKey="consentsNotAgreedWith" />);
    if (!(passengersValid && purchaserValid))
      toast.error(<TransAlert i18nKey="mandatoryFieldsMustBeFilled" />);

    if (passengersValid && purchaserValid && consentsAgreedWith) {
      await passengerListForm.handleSubmit((form) =>
        dispatch(updatePurchaseFlowBookingPassengers(form.passengers)).unwrap()
      )();
      await purchaserForm.handleSubmit((form) =>
        dispatch(updatePurchaseFlowBookingPurchaser(form)).unwrap()
      )();

      handlePayment()
        .then(() => {
          if (!booking?.id || !booking?.code || !payerEmail) {
            throw new Error('Missing booking data');
          }

          setCheckoutSuccessSessionStorage({
            bookingId: booking.id,
            code: booking.code,
            payerEmail: payerEmail,
            fulfillmentIds: getFulfillmentIds(booking),
          });

          dispatch(resetPurchase());
          dispatch(clearPurchaseFlowBooking());
          persistor.purge();
          navigate('/success');
        })
        .catch(() => toast.error(<TransAlert i18nKey="paymentFailed" />));
    }
  }, [
    passengerListForm,
    purchaserForm,
    consentsForm,
    handlePayment,
    dispatch,
    booking,
    setCheckoutSuccessSessionStorage,
    navigate,
  ]);

  const handleCancelBooking = useCallback(async () => {
    if (!booking?.id) {
      return;
    }

    await dispatch(cancelPurchaseFlowBooking(booking.id))
      .unwrap()
      .then(() => {
        persistor.purge();
        dispatch(setActiveStep(STEP.PreSearchSubmit));
      })
      .catch(() => {
        toast.error(<TransAlert i18nKey="bookingCancellationFailed" />);
      });
  }, [booking?.id, dispatch]);

  const parsePassengerValuesToPassengerDto = useCallback(
    (passengers: Array<PassengerValues>): Array<PassengerDTO> =>
      passengers.map(({ age, firstName, lastName, email, phone, ...rest }) => ({
        ...rest,
        age: age ?? undefined,
        firstName: firstName
          ? { value: firstName, isRequired: false }
          : undefined,
        lastName: lastName ? { value: lastName, isRequired: false } : undefined,
        contactInformation:
          !email && !phone.number
            ? undefined
            : {
                isRequired: false,
                ...(email && {
                  emailAddress: { isRequired: false, value: email },
                }),
                ...(phone.number && {
                  phoneNumber: {
                    isRequired: false,
                    value: phone.number,
                  },
                }),
              },
        isCardsRequired: false,
      })),
    []
  );

  const renderBookingExpirationTimer = () => {
    return (
      <div className="flex h-8 w-20 items-center justify-between rounded-md bg-white p-2 text-primary">
        <Icons.commerce />
        <BookingExpirationTimer />
      </div>
    );
  };

  const summaryData = useBookingSummaryData(
    {
      ...booking,
      passengers: parsePassengerValuesToPassengerDto(formPassengers),
    },
    ['PREBOOKED', 'CONFIRMED', 'FULFILLED']
  );

  return (
    <Loadable loading={loading} overlay>
      <div className="flex items-start gap-8">
        <div className="flex flex-1 flex-col gap-4">
          <div className="sticky top-0 z-10 flex justify-between bg-background py-2">
            <Typography variant="heading1" asChild>
              <h1>
                <TransText i18nKey="checkout" />
              </h1>
            </Typography>
            {!isLaptopOrBigger && renderBookingExpirationTimer()}
          </div>
          {getSections().map(([paragraphKey, section], key) => (
            <ContentCard key={key}>
              <div className="flex flex-col gap-3 p-4">
                <Typography
                  variant="subtitle"
                  className="flex gap-1 text-dark"
                  asChild
                >
                  <h2>
                    <span>{key + 1}.</span>
                    <TransText i18nKey={paragraphKey} />
                  </h2>
                </Typography>
                <Divider className="border-neutral-light" />
                {section}
              </div>
            </ContentCard>
          ))}
        </div>
        {isLaptopOrBigger && (
          <div className="flex flex-col gap-4">
            <div className="sticky top-0 z-10 flex justify-between bg-background py-2">
              <Typography variant="heading2" asChild>
                <h1>
                  <TransText i18nKey="bookingSummary" />
                </h1>
              </Typography>
              {renderBookingExpirationTimer()}
            </div>
            <RegularBookingSummary {...summaryData} flow={Flow.purchase} />
          </div>
        )}
      </div>
      <Footer
        content={
          !isLaptopOrBigger && (
            <MobileBookingSummary {...summaryData} flow={Flow.purchase} />
          )
        }
        actionButtons={
          <div className="flex w-full flex-col gap-4 laptop:flex-row laptop:gap-2">
            <Consents consentsForm={consentsForm} />
            <div className="flex justify-end gap-2">
              <ConfirmModal
                onConfirm={handleCancelBooking}
                description={
                  <TransText i18nKey="areYouSureYouWantToCancelYourBooking" />
                }
              >
                <Button
                  size="large"
                  fullWidth
                  data-testid="cancel-booking-button"
                  className="rounded-lg laptop:h-11 laptop:w-auto"
                  variant="destructive"
                >
                  <Icons.cancel />
                  <Typography variant="button">
                    <TransText i18nKey="cancelBooking" />
                  </Typography>
                </Button>
              </ConfirmModal>
              <Button
                size="large"
                fullWidth
                data-testid="confirm-purchase-button"
                className="rounded-lg laptop:h-11 laptop:w-auto"
                onClick={handlePayClicked}
                aria-label="Pay button"
              >
                <Icons.lock />
                <Typography variant="subtitle" className="flex gap-1">
                  <TransText i18nKey="pay" />
                  <span>({amount}</span>
                  <span>{currency.symbol})</span>
                </Typography>
              </Button>
            </div>
          </div>
        }
      />
    </Loadable>
  );
};

export default Checkout;
