import { type FC, useMemo } from 'react';
import type { LegDTO, AdmissionDTO } from '@/types/dto';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { format } from 'date-fns';
import {
  formatTime,
  formatDuration,
  getDurationBetweenTimestamps,
} from '@/utils/date-time';
import ProductDetails from '@/components/manage/ProductDetails';
import _uniq from 'lodash/uniq';
import { Badge } from '@/components/primitives/Badge';
import { cn } from '@/utils/cn';
import { TransText } from '@/i18n/trans/text';
import { getPtModeIconName } from '@/utils/icon';
import { TransportationTypeIcon } from '@/components/icons/TransportationTypeIcon';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/primitives/Tooltip';
import TicketCuticle from '@/components/manage/TicketCuticle';
import TransferTime from '@/components/manage/TransferTime';

interface LegCardProps {
  leg: LegDTO;
  nextLeg?: LegDTO;
  admissions: Array<AdmissionDTO>;
}

const LegCard: FC<LegCardProps> = ({ leg, nextLeg, admissions }) => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const productDescription = admissions[0].productDescription;

  const passengerCount = useMemo(() => {
    const passengerIds = admissions.reduce<Array<string>>(
      (ids, admission) => [...ids, ...(admission.passengerIds || [])],
      []
    );
    return _uniq(passengerIds).length;
  }, [admissions]);

  const admissionsAmount = useMemo(() => {
    return admissions.reduce((sum, admission) => {
      if (admission.status === 'REFUNDED') {
        return sum;
      }

      const ancillariesAmount = admission.ancillaries?.reduce(
        (total, ancillary) =>
          total + (ancillary.price?.amount || 0) * (ancillary.amountOf || 1),
        0
      );
      return sum + (admission.price?.amount || 0) + (ancillariesAmount || 0);
    }, 0);
  }, [admissions]);

  const isAllRefunded = useMemo(
    () => admissions.every((admission) => admission.status === 'REFUNDED'),
    [admissions]
  );
  const isPartiallyRefunded = useMemo(
    () => admissions.some((admission) => admission.status === 'REFUNDED'),
    [admissions]
  );

  const isAllExchanged = useMemo(
    () => admissions.every((admission) => admission.status === 'EXCHANGED'),
    [admissions]
  );

  const transferTime = nextLeg
    ? getDurationBetweenTimestamps(leg.arrivalTime, nextLeg.departureTime)
    : null;

  const renderTransportBadge = () => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Badge
            variant={isAllRefunded ? 'disabled' : 'light'}
            className="rounded-sm"
          >
            <TransportationTypeIcon
              ptModeIcon={getPtModeIconName(leg.ptMode)}
            />
            <Typography variant="body2-bold">{leg.lineNumber}</Typography>
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <Typography variant="body2">
            <TransText i18nKey={getPtModeIconName(leg.ptMode)} />
          </Typography>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  const renderTripDetails = () => (
    <div className="flex flex-row gap-2">
      <div className="flex w-fit flex-col">
        <Typography
          variant="times"
          asChild
          className={cn({ 'line-through': isAllRefunded })}
        >
          <h2>{formatTime(leg.departureTime)}</h2>
        </Typography>
        <Typography variant="body2">{leg.originStop?.name}</Typography>
      </div>
      <div
        className={cn(
          'mt-2 flex h-1/2 w-16 flex-row items-center gap-0.5 text-neutral',
          {
            'mt-1': isLaptopOrBigger,
          }
        )}
      >
        <Icons.line />
        <Typography variant="caption" className="whitespace-nowrap">
          {formatDuration(leg.duration)}
        </Typography>
        <Icons.line />
      </div>
      <div className="flex w-fit flex-col">
        <Typography
          variant="times"
          asChild
          className={cn({ 'line-through': isAllRefunded })}
        >
          <h2>{formatTime(leg.arrivalTime)}</h2>
        </Typography>
        <Typography variant="body2">{leg.destinationStop?.name}</Typography>
      </div>
    </div>
  );

  const renderDate = () =>
    leg.departureTime && (
      <Typography variant="body2" className="text-neutral">
        {format(leg.departureTime, 'EEE, dd MMM yyyy')}
      </Typography>
    );

  const renderMobile = () => {
    return (
      <div
        className={cn(
          'flex flex-col rounded-lg border border-neutral-light bg-white text-dark',
          { 'bg-neutral-pale text-neutral': isAllRefunded }
        )}
      >
        <div className="flex flex-row items-center justify-between p-3">
          {renderTransportBadge()}
          {renderDate()}
        </div>
        <div className="px-3 pb-3">
          {renderTripDetails()}
          {isAllRefunded && (
            <Badge variant="negative" className="mt-3 h-1/4">
              <Icons.ticketReturn height={16} width={16} />
              <TransText i18nKey="refunded" />
            </Badge>
          )}
          {isPartiallyRefunded && !isAllRefunded && (
            <Badge variant="attention" className="mt-3 h-1/4">
              <Icons.infoCircle height={16} width={16} />
              <TransText i18nKey="partiallyRefunded" />
            </Badge>
          )}
        </div>
        <div className="py-3">
          <TicketCuticle isVertical={false} />
        </div>
        <div className="px-3 pb-3">
          <ProductDetails
            isAllRefunded={isAllRefunded}
            passengerCount={passengerCount}
            description={productDescription}
            admissionsAmount={admissionsAmount}
          />
        </div>
      </div>
    );
  };

  const renderLaptopOrBigger = () => {
    return (
      <div
        className={cn(
          'relative my-3 flex flex-row rounded-lg border border-neutral-light bg-white text-dark laptop:flex-row',
          { 'bg-neutral-pale text-neutral': isAllRefunded }
        )}
      >
        <div className="flex w-full flex-row justify-between">
          <div className="flex flex-row gap-2 p-4">{renderTripDetails()}</div>
          {isAllRefunded && (
            <Badge variant="negative" className="mt-3 h-1/4">
              <Icons.ticketReturn height={16} width={16} />
              <TransText i18nKey="refunded" />
            </Badge>
          )}
          {isPartiallyRefunded && !isAllRefunded && (
            <Badge variant="attention" className="mt-3 h-1/4">
              <Icons.infoCircle height={16} width={16} />
              <TransText i18nKey="partiallyRefunded" />
            </Badge>
          )}
        </div>

        <div className="pl-4">
          <TicketCuticle isVertical={true} />
        </div>

        <div className="flex w-2/3 flex-col gap-4 p-3 pl-2">
          <div className="flex flex-row items-center justify-between">
            {renderTransportBadge()}
            {renderDate()}
          </div>
          <ProductDetails
            isAllRefunded={isAllRefunded}
            passengerCount={passengerCount}
            description={productDescription}
            admissionsAmount={admissionsAmount}
          />
        </div>
      </div>
    );
  };

  if (isAllExchanged) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {isLaptopOrBigger ? renderLaptopOrBigger() : renderMobile()}
      {Boolean(transferTime) && (
        <TransferTime
          formattedTime={transferTime}
          arrivalStation={leg.destinationStop?.name}
          departureStation={nextLeg?.originStop?.name}
        />
      )}
    </div>
  );
};

export default LegCard;
