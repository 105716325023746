interface TicketCuticleDotProps {
  isVertical: boolean;
  position: 'start' | 'end';
}

const TicketCuticleDot = ({ isVertical, position }: TicketCuticleDotProps) => {
  const positionClasses = isVertical
    ? position === 'start'
      ? '-left-3 -top-[1px]'
      : '-bottom-[1px] -left-3'
    : position === 'start'
      ? '-left-[1px] -top-3'
      : '-right-[1px] -top-3';

  const shapeClasses = isVertical
    ? position === 'start'
      ? 'h-2.5 w-5 rounded-b-full border-b border-l border-r'
      : 'h-2.5 w-5 rounded-t-full border-l border-r border-t'
    : position === 'start'
      ? 'h-5 w-2.5 rounded-r-full border-b border-r border-t'
      : 'h-5 w-2.5 rounded-l-full border-b border-l border-t';

  return (
    <div className={`absolute ${positionClasses}`}>
      <div
        className={`${shapeClasses} border-neutral-light bg-background [transform-style:preserve-3d] [transform:rotate(0.001deg)]`}
      />
    </div>
  );
};

export default TicketCuticleDot;
