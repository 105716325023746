import { Icons } from '@/components/icons/index';

export const TransportationTypeIcon = ({
  ptModeIcon,
  className,
}: {
  ptModeIcon: 'train' | 'bus';
  className?: string;
}) => {
  const IconComponent = Icons[ptModeIcon];

  return <IconComponent height={16} width={16} className={className} />;
};
