import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useTicketDownload } from '@/hooks/useTicketDownload';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import Divider from '@/components/primitives/Divider';
import { Button } from '@/components/primitives/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '@/store/utils';
import { Loadable } from '@/components/Loadable';
import { successLoadingSelector } from '@/features/loading/loadingSelectors';
import { usePkPassDownload } from '@/hooks/usePkPassDownload';
import { sendPurchaseConfirmation } from '@/features/purchase/purchaseService';
import { toast } from 'react-toastify';
import { STORAGE_KEYS, useSessionStorage } from '@/hooks/useSessionStorage';
import type { CheckoutSuccessSessionStorageData } from '@/types/session-storage';

const Success = () => {
  const navigate = useNavigate();
  const loading = useSelector(successLoadingSelector);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPkPassDownloading, setIsPkPassDownloading] = useState(false);
  const { value: successData, removeValue } =
    useSessionStorage<CheckoutSuccessSessionStorageData>(
      STORAGE_KEYS.CHECKOUT_SUCCESS_DATA
    );

  const handleTicketDownload = useTicketDownload({
    bookingId: successData?.bookingId,
    onStart: () => setIsDownloading(true),
    onFinish: () => setIsDownloading(false),
  });

  const handlePkPassDownload = usePkPassDownload({
    fulfillmentIds: successData?.fulfillmentIds,
    onStart: () => setIsPkPassDownloading(true),
    onFinish: () => setIsPkPassDownloading(false),
  });

  useEffect(() => {
    if (!successData?.bookingId || !successData?.payerEmail) {
      navigate('/', { replace: true });
      return;
    }

    sendPurchaseConfirmation(successData.bookingId, [
      successData.payerEmail,
    ]).catch(() => {
      toast.error(<TransText i18nKey="errorSendingEmail" />);
    });

    return () => {
      removeValue();
    };
  }, [navigate, removeValue, successData]);

  if (!successData?.bookingId) {
    return null;
  }

  return (
    <Loadable loading={loading}>
      <div className="mx-auto mb-28 w-full p-5 tablet:mb-16 tablet:max-w-mobile laptop:max-w-tablet desktop:max-w-laptop">
        <div className="pt-16 tablet:pt-[100px]">
          <div className="grid gap-2 md:grid-cols-1 desktop:grid-cols-2 desktop:gap-10">
            <div className="flex flex-col">
              <div className="flex h-12 items-center gap-3">
                <Icons.checkCircle className="h-7 w-7" />
                <Typography
                  variant={isLaptopOrBigger ? 'title' : 'mobile-title'}
                >
                  <TransText i18nKey="ticketPurchased" />
                </Typography>
              </div>
              <Typography variant="paragraph" className="mb-4 mt-2">
                <TransText
                  i18nKey="orderNo"
                  values={{ number: successData.code }}
                />
              </Typography>
              <Divider />
              <Typography variant="body1" className="mt-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed
                auctor orci. Cras id iaculis velit. Donec sit amet nisl
                fringilla turpis ornare semper vel sit amet velit.
              </Typography>
            </div>

            <div className="mt-4 desktop:mt-0">
              <div className="flex h-20 flex-col gap-4 mobile:mb-4 tablet:flex-row tablet:items-center">
                <Button
                  fullWidth
                  className="desktop:w-fit"
                  data-testid="download-pdf-button"
                  variant="tertiary"
                  size="medium"
                  onClick={handleTicketDownload}
                  disabled={isDownloading || !successData.bookingId}
                >
                  {isDownloading ? (
                    <Icons.loader className="h-4 w-4 animate-spin" />
                  ) : (
                    <Icons.download height={16} width={16} />
                  )}
                  <TransText i18nKey="downloadPdf" />
                </Button>
                <Button
                  fullWidth
                  size="medium"
                  className="desktop:w-fit"
                  data-testid="download-pkpass-button"
                  variant="tertiary"
                  onClick={handlePkPassDownload}
                  disabled={isPkPassDownloading || !successData.bookingId}
                >
                  {isPkPassDownloading ? (
                    <Icons.loader className="h-4 w-4 animate-spin" />
                  ) : (
                    <Icons.file height={16} width={16} />
                  )}
                  <TransText i18nKey="downloadPkpass" />
                </Button>
              </div>
              <Divider className="mb-6 mt-10 tablet:mt-0" />
              <Typography variant="paragraph">
                <TransText i18nKey="ticketSent" />
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Loadable>
  );
};

export default Success;
