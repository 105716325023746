import type { RefundOffersDTO, AdmissionDTO, BookingDTO } from '@/types/dto';
import getApiInstance from '@/api';
import { createAsyncThunk } from '@/store/utils';
import { createAction } from '@reduxjs/toolkit';
import { runInSequence } from '@/utils/runInSequence';

export const resetRefundSelection = createAction('manage/resetRefundSelection');

export const toggleRefundLeg = createAction(
  'manage/toggleRefundLeg',
  function prepare(
    legId: string,
    selectedAdmissions: Record<string, AdmissionDTO[]>,
    admissions: AdmissionDTO[] | []
  ) {
    const legAdmissions = selectedAdmissions[legId] || [];
    const areAllSelected = legAdmissions.length === admissions?.length;

    return {
      payload: {
        legId,
        admissions: areAllSelected ? [] : admissions,
      },
    };
  }
);

export const toggleRefundAdmission = createAction(
  'manage/toggleRefundAdmission',
  function prepare(
    legId: string,
    selectedAdmissions: Record<string, AdmissionDTO[]>,
    admission: AdmissionDTO
  ) {
    const legAdmissions = selectedAdmissions[legId] || [];
    const updatedLegAdmissions = legAdmissions.some(
      (a) => a.id === admission?.id
    )
      ? legAdmissions.filter((a) => a.id !== admission?.id)
      : [...legAdmissions, admission];

    return {
      payload: {
        legId,
        admissions: updatedLegAdmissions,
      },
    };
  }
);

export const initiateRefund = createAsyncThunk<RefundOffersDTO | null, void>(
  'manage/initiateRefund',
  async (_, { getState }) => {
    const state = getState();
    const { selectedAdmissions } = state.manage.refund;
    const bookingId = state.manage.booking?.id;

    if (!bookingId) {
      return null;
    }

    const fulfillmentIds = Object.values(selectedAdmissions)
      .flat()
      .filter((admission) => admission.status !== 'REFUNDED')
      .flatMap((admission) => [
        ...(admission.fulfillments ?? []).map((f) => f.id),
        ...(admission.ancillaries?.flatMap(
          (ancillary) => ancillary.fulfillments?.map((f) => f.id) ?? []
        ) ?? []),
      ])
      .filter((item): item is string => Boolean(item));

    const api = (await getApiInstance()).agentApi;
    const response = await api.RefundOffers_InitiateRefund(
      { bookingId },
      {
        fulfillmentIds: [fulfillmentIds[0], ...fulfillmentIds.slice(1)],
      }
    );
    return response.data;
  }
);

export const deleteRefundOffer = createAsyncThunk<void, void>(
  'manage/deleteRefundOffer',
  async (_, { getState }) => {
    const state = getState();
    const bookingId = state.manage.booking?.id;

    if (!bookingId) {
      return;
    }

    const api = (await getApiInstance()).agentApi;

    await runInSequence(
      (state.manage.refund.details?.refundOffers ?? []).map(
        (offer) => () =>
          offer.id &&
          api.RefundOffers_DeleteRefund({
            bookingId,
            refundOfferId: offer.id,
          })
      )
    );
  }
);

export const confirmRefundOffer = createAsyncThunk<void, void>(
  'manage/confirmRefundOffer',
  async (_, { getState }) => {
    const state = getState();
    const bookingId = state.manage.booking?.id;
    const api = (await getApiInstance()).agentApi;

    if (!bookingId) {
      return;
    }

    await runInSequence(
      (state.manage.refund.details?.refundOffers ?? []).map(
        (offer) => () =>
          offer.id &&
          api.RefundOffers_ConfirmRefund(
            { bookingId, refundOfferId: offer.id },
            { status: 'CONFIRMED' }
          )
      )
    );
  }
);

export const getManageFlowBookingById = createAsyncThunk<
  BookingDTO | null,
  string | null | undefined
>('manage/getManageFlowBookingById', async (id) => {
  if (!id) {
    return null;
  }

  const api = (await getApiInstance()).agentApi;

  return (await api.Bookings_GetBooking(id)).data;
});

export const getManageFlowBookingByNumberAndEmail = createAsyncThunk<
  BookingDTO | null,
  {
    bookingNumber: string;
    email: string;
  }
>(
  'manage/getManageFlowBookingByNumberAndEmail',
  async ({ bookingNumber, email }) => {
    const api = (await getApiInstance()).agentApi;

    const searchResults = (
      await api.BookingsSearch_SearchBookingsCustom(null, {
        reference: bookingNumber,
        purchaserPassengerEmail: email,
        concessionVoucherSearchType: 'ALL',
      })
    ).data.bookingsSearchResults;

    if (searchResults?.length !== 1) {
      throw new Error(
        `Expected 1 search result, found ${searchResults?.length}.`
      );
    }

    const booking = searchResults[0];

    if (!booking.id) {
      return null;
    }

    return (await api.Bookings_GetBooking({ bookingId: booking.id })).data;
  }
);

export const clearManageFlowBooking = createAction(
  'manage/clearManageFlowBooking'
);
