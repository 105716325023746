import { type FC, useCallback, useState } from 'react';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import { Typography } from '@/components/primitives/Typography';
import { useDispatch, useSelector } from '@/store/utils';
import { Button } from '@/components/primitives/Button';
import useBookingSummaryData from '@/hooks/useBookingSummaryData';
import RefundModal from '@/components/manage/refund/RefundModal';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import RegularBookingSummary from '@/components/booking-summary/RegularBookingSummary';
import MobileBookingSummary from '@/components/booking-summary/MobileBookingSummary';
import { useBookingRefundEligibleTrips } from '@/hooks/useBookingRefundEligibleTrips';
import { manageFlowBookingSelector } from '@/features/manage/manageSelectors';
import { Flow } from '@/types/booking';
import { clearManageFlowBooking } from '@/features/manage/manageActions';
import Footer from '@/components/Footer';
import BookedTripsDetails from '@/components/manage/BookedTripsDetails';
import TravelPasses from '@/components/manage/travel-passes/TravelPasses';
import Divider from '@/components/primitives/Divider';
import { useTranslation } from 'react-i18next';

interface BookingDetailsProps {}

export const BookingDetails: FC<BookingDetailsProps> = () => {
  const dispatch = useDispatch();
  const booking = useSelector(manageFlowBookingSelector);
  const summaryData = useBookingSummaryData(booking, [
    'CONFIRMED',
    'EXCHANGED',
    'REFUNDED',
    'FULFILLED',
  ]);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const { refundEligibleOutboundTrips, refundEligibleInboundTrips } =
    useBookingRefundEligibleTrips(booking);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const { t } = useTranslation();

  const handleGoBack = useCallback(() => {
    dispatch(clearManageFlowBooking());
  }, [dispatch]);

  return (
    <div className="mx-auto mb-28 flex w-full flex-col p-5 tablet:mb-16 tablet:max-w-mobile laptop:max-w-tablet desktop:max-w-laptop">
      <div id="go-back-section" className="flex items-center gap-2">
        <Button
          data-testid="go-back-button"
          className="-ml-2 p-2"
          variant="icon"
          aria-label={t('text.goBack', 'Go back')}
          onClick={handleGoBack}
        >
          <Icons.arrowLeft />
        </Button>

        <div className="flex items-baseline gap-1">
          <Typography
            variant={isLaptopOrBigger ? 'heading1' : 'subtitle'}
            asChild
          >
            <h1>
              <TransText i18nKey="booking" />
              &#58;
            </h1>
          </Typography>
          <Typography variant={isLaptopOrBigger ? 'heading2-light' : 'body1'}>
            {booking?.code}
          </Typography>
        </div>
      </div>
      <Divider className="my-6 border-neutral" />
      <div className="flex flex-col laptop:flex-row laptop:gap-10">
        {booking?.bookedTrips?.length ? (
          <BookedTripsDetails />
        ) : (
          <TravelPasses />
        )}
        {isLaptopOrBigger && (
          <div className="flex flex-col gap-7">
            <Typography variant="heading2" asChild>
              <h1>
                <TransText i18nKey="bookingSummary" />
              </h1>
            </Typography>
            <RegularBookingSummary {...summaryData} flow={Flow.manageBooking} />
          </div>
        )}
      </div>
      <Footer
        content={
          !isLaptopOrBigger && (
            <MobileBookingSummary {...summaryData} flow={Flow.manageBooking} />
          )
        }
        actionButtons={
          (!!refundEligibleOutboundTrips.length ||
            !!refundEligibleInboundTrips.length) && (
            <Button
              fullWidth
              size="regular"
              variant="secondary"
              data-testid="refund-button"
              className="rounded-lg laptop:h-11 laptop:w-auto"
              onClick={() => setIsRefundModalOpen(true)}
            >
              <Icons.ticketReturn />
              <Typography variant="button">
                <TransText i18nKey="refund" />
              </Typography>
            </Button>
          )
        }
      />
      <RefundModal
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      />
    </div>
  );
};
