import type { FC, ReactNode } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import { Button } from '@/components/primitives/Button';

interface ShowMoreButtonProps {
  expanded: boolean;
  onClick: () => void;
  labelWhenExpanded: ReactNode;
  labelWhenCompressed: ReactNode;
}

const ShowMoreButton: FC<ShowMoreButtonProps> = ({
  expanded,
  onClick,
  labelWhenExpanded,
  labelWhenCompressed,
}) => (
  <Button
    variant="ghost"
    data-testid="read-more-button"
    className="px-0 py-1"
    onClick={onClick}
    type="button"
  >
    <Typography variant="button" className="flex items-center gap-1">
      {expanded ? (
        <>
          {labelWhenExpanded}
          <Icons.chevronUp className="h-4 w-4" />
        </>
      ) : (
        <>
          {labelWhenCompressed}
          <Icons.chevronDown className="h-4 w-4" />
        </>
      )}
    </Typography>
  </Button>
);

export default ShowMoreButton;
