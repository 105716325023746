import { useDispatch, useSelector } from '@/store/utils';
import { intervalToDuration, isAfter } from 'date-fns';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { purchaseFlowBookingConfirmableUntilSelector } from '@/features/purchase/purchaseSelectors';
import { persistor } from '@/store';
import { setActiveStep } from '@/features/purchase/purchaseActions';
import { STEP } from '@/utils/consts';
import ExpiryWarningDialog from '@/components/purchase/checkout/expiration-timer/ExpiryWarningDialog';
import BookingExpiredDialog from '@/components/purchase/checkout/expiration-timer/BookingExpiredDialog';
import type { ExpiryData } from '@/types/manage';

let expiryInterval: ReturnType<typeof setInterval>;

const BookingExpirationTimer: FC = () => {
  const dispatch = useDispatch();
  const confirmableUntil = useSelector(
    purchaseFlowBookingConfirmableUntilSelector
  );
  const [expiryWarningDismissed, setExpiryWarningDismissed] = useState(false);
  const [expiryWarningDialogOpen, setExpiryWarningDialogOpen] = useState(false);
  const [bookingExpiredDialogOpen, setBookingExpiredDialogOpen] =
    useState(false);

  const parsedConfirmableUntil = useMemo(
    () => (confirmableUntil ? new Date(confirmableUntil) : new Date()),
    [confirmableUntil]
  );

  const [expiryData, setExpiryData] = useState<ExpiryData>(
    getExpiryData(new Date(), parsedConfirmableUntil)
  );

  const updateExpiryCount = useCallback(() => {
    setExpiryData(getExpiryData(new Date(), parsedConfirmableUntil));

    if (
      !expiryWarningDialogOpen &&
      !expiryWarningDismissed &&
      expiryData.showExpiryWarning
    )
      setExpiryWarningDialogOpen(true);

    if (expiryData.isExpired) {
      setBookingExpiredDialogOpen(true);
      clearInterval(expiryInterval);
    }
  }, [
    expiryData.isExpired,
    expiryData.showExpiryWarning,
    expiryWarningDialogOpen,
    expiryWarningDismissed,
    parsedConfirmableUntil,
  ]);

  useEffect(() => {
    expiryInterval = setInterval(updateExpiryCount, 1000);

    return () => clearInterval(expiryInterval);
  }, [updateExpiryCount]);

  const handleExpiryWarningModalClose = useCallback(() => {
    setExpiryWarningDismissed(true);
    setExpiryWarningDialogOpen(false);
  }, []);

  const handleBookingExpiredModalClose = useCallback(() => {
    setBookingExpiredDialogOpen(false);
    persistor.purge();
    dispatch(setActiveStep(STEP.PreSearchSubmit));
  }, [dispatch]);

  return (
    <>
      {expiryData.timeTillExpiry}
      <ExpiryWarningDialog
        open={expiryWarningDialogOpen}
        onClose={handleExpiryWarningModalClose}
        timeTillExpiry={expiryData.timeTillExpiry}
      />
      <BookingExpiredDialog
        open={bookingExpiredDialogOpen}
        onClose={handleBookingExpiredModalClose}
      />
    </>
  );
};

const getExpiryData = (start: Date, end: Date): ExpiryData => {
  const isExpired = isAfter(start, end);
  const durationTillExpiry = intervalToDuration({
    start,
    end: isExpired ? start : end,
  });

  const timeTillExpiry =
    durationTillExpiry &&
    [durationTillExpiry.minutes ?? 0, durationTillExpiry.seconds ?? 0]
      .map((num) => String(num).padStart(2, '0'))
      .join(':');

  const showExpiryWarning = (durationTillExpiry.minutes ?? 0) < 5;

  return {
    timeTillExpiry,
    isExpired,
    showExpiryWarning,
  };
};

export default BookingExpirationTimer;
