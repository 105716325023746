import type { FC } from 'react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icons } from '@/components/icons';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '@/components/primitives/NavigationMenu';
import type { NavigationItem } from '@/types/navigation';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import { cn } from '@/utils/cn';
import RegularLanguageSelect from '@/components/header/language-select/RegularLanguageSelect';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';

type RegularNavMenuProps = {
  menuItems: Array<NavigationItem>;
};

const RegularNavMenu: FC<RegularNavMenuProps> = ({ menuItems }) => {
  const location = useLocation();
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  return (
    <div
      className="mx-auto flex w-full items-center justify-between text-white"
      data-testid="navbar-regular"
    >
      <div className="flex items-center gap-6">
        <Link to="/" aria-label="Hero">
          <Icons.turnitLogo />
        </Link>
        <nav className="flex items-center">
          <NavigationMenu>
            <NavigationMenuList className="flex items-center gap-4">
              {menuItems.map((item, index) => (
                <React.Fragment key={item.path}>
                  <NavigationMenuItem>
                    <Link
                      to={item.path}
                      onClick={item.onMenuItemClick}
                      className={cn(navigationMenuTriggerStyle(), {
                        'text-accent': location.pathname === item.path,
                      })}
                    >
                      <Typography variant="button" asChild>
                        {item.name}
                      </Typography>
                    </Link>
                  </NavigationMenuItem>
                  {index < menuItems.length - 1 && (
                    <Divider
                      orientation="vertical"
                      className="h-4 border-neutral-pale"
                    />
                  )}
                </React.Fragment>
              ))}
            </NavigationMenuList>
          </NavigationMenu>
        </nav>
      </div>
      {isLaptopOrBigger && <RegularLanguageSelect />}
    </div>
  );
};

export default RegularNavMenu;
