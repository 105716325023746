import TicketCuticleDot from './TicketCuticleDot';
import TicketCuticleLine from './TicketCuticleLine';

interface TicketCuticleProps {
  isVertical?: boolean;
}

const TicketCuticle = ({ isVertical = true }: TicketCuticleProps) => {
  return (
    <div
      className={`relative flex items-stretch ${
        isVertical ? 'h-full' : 'w-full'
      }`}
    >
      <TicketCuticleDot isVertical={isVertical} position="start" />
      <TicketCuticleLine isVertical={isVertical} />
      <TicketCuticleDot isVertical={isVertical} position="end" />
    </div>
  );
};

export default TicketCuticle;
