import type { FC } from 'react';
import type { PaymentTypeDTO } from '@/types/dto';
import type { UseFormReturn } from 'react-hook-form';
import type { PaymentMethodValues } from '@/utils/zod/schema';
import { Typography } from '@/components/primitives/Typography';
import PaymentMethodRow from '@/components/purchase/checkout/payment-method/PaymentMethodRow';
import { TransText } from '@/i18n/trans/text';
import { paymentMethods } from '@/utils/consts';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { cn } from '@turnit-ride-ui/webshop-search-widget/widget/utils/cn';

interface PaymentMethodProps {
  paymentMethodForm: UseFormReturn<PaymentMethodValues>;
}

const PaymentMethod: FC<PaymentMethodProps> = ({ paymentMethodForm }) => {
  const selectedPaymentMethodId = paymentMethodForm.watch('id');
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const renderPaymentMethodRow = (method: PaymentTypeDTO, key: number) => {
    const handleRowClick = () => {
      if (!method.id) {
        return;
      }

      paymentMethodForm.setValue('id', method.id);
    };

    return (
      <PaymentMethodRow
        method={method}
        key={key}
        onClick={handleRowClick}
        isActive={selectedPaymentMethodId === method.id}
      />
    );
  };

  return (
    <div className="grid w-full grid-cols-1 flex-col gap-4 laptop:grid-cols-[1fr,1fr] laptop:gap-0">
      <div id="banks-section" className="flex flex-col items-start gap-2">
        <Typography variant="subtitle" className="text-dark">
          <TransText i18nKey="banks" />
        </Typography>
      </div>
      <div
        id="other-payment-methods-section"
        className={cn('flex flex-col gap-2', {
          'border-l border-neutral-light pl-3': isLaptopOrBigger,
        })}
      >
        <Typography variant="subtitle" className="text-dark">
          <TransText i18nKey="other" />
        </Typography>
        {paymentMethods.map(renderPaymentMethodRow)}
      </div>
    </div>
  );
};

export default PaymentMethod;
