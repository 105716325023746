import type { FC } from 'react';
import { getLanguage, localeConfigurations } from '@/i18n';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import _toUpper from 'lodash/toUpper';
import { useTranslation } from 'react-i18next';
import { cn } from '@/utils/cn';

interface LanguageSelectContentProps {}

const LanguageSelectContent: FC<LanguageSelectContentProps> = () => {
  const {
    i18n: { changeLanguage },
  } = useTranslation();
  const currentLanguage = getLanguage();

  return Object.entries(localeConfigurations).map((entry, key) => {
    const isActive = entry[0] === currentLanguage;

    return (
      <div
        className={cn(
          'flex h-10 w-full items-center justify-between px-3 py-2 text-dark hover:cursor-pointer hover:bg-primary-pale',
          {
            'bg-primary-light': isActive,
          }
        )}
        data-testid={`language-select-choose-${entry[0]}`}
        onClick={async () => {
          await changeLanguage(entry[0]);
          window.location.reload();
        }}
        key={key}
      >
        <Typography variant="body1">{entry[1].label}</Typography>
        <div className="flex items-center gap-2">
          {isActive && (
            <Icons.checkCircle
              height={16}
              width={16}
              className="text-success-text"
            />
          )}
          {_toUpper(entry[0])}
        </div>
      </div>
    );
  });
};

export default LanguageSelectContent;
