import { type FC, useState } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { Button } from '@/components/primitives/Button';
import { cn } from '@turnit-ride-ui/webshop-search-widget/widget/utils/cn';
import { TransText } from '@/i18n/trans/text';
import { Icons } from '@/components/icons';

interface ExpandableTextProps {
  text: string | undefined;
  maxCharacterLimit?: number;
}

const ExpandableText: FC<ExpandableTextProps> = ({
  text,
  maxCharacterLimit = 200,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!text) {
    return null;
  }
  const characterLimitExceeded = text.length > maxCharacterLimit;

  return (
    <div>
      <div
        className={cn('transition-all duration-300', {
          'line-clamp-none': isExpanded,
          'line-clamp-2': !isExpanded,
        })}
      >
        <Typography variant="body2" className="leading-snug">
          {text}
        </Typography>
      </div>
      {characterLimitExceeded && (
        <Button
          variant="ghost"
          data-testid="read-more-button"
          className={cn('h-auto px-0 py-0', {
            'mb-3': isExpanded,
          })}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Typography variant="body2" className="flex items-center gap-1">
            {isExpanded ? (
              <>
                <TransText i18nKey="showLess" />
                <Icons.chevronUp className="h-3 w-3" />
              </>
            ) : (
              <>
                <TransText i18nKey="readMore" />
                <Icons.chevronDown className="h-3 w-3" />
              </>
            )}
          </Typography>
        </Button>
      )}
    </div>
  );
};

export default ExpandableText;
