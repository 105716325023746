import type { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/primitives/Dialog';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';

interface BookingExpiredDialogProps {
  open: boolean;
  onClose: () => void;
}

const BookingExpiredDialog: FC<BookingExpiredDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className="tablet:w-[480px]"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader className="border-none px-6 py-4" showClose={false}>
          <DialogTitle>
            <Typography>
              <TransText i18nKey="bookingSessionExpired" />
            </Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-6 pb-6">
          <Typography className="text-dark">
            <TransText i18nKey="bookingSessionExpiryDescription" />
          </Typography>
        </DialogDescription>
        <DialogFooter className="items-end px-6 pb-3">
          <Button
            variant="primary"
            className="w-full rounded-lg tablet:w-min"
            data-testid="booking-expiry-modal-action-button"
            onClick={onClose}
          >
            <TransText i18nKey="backToJourneySearch" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BookingExpiredDialog;
