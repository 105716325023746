import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Icons } from '@/components/icons';
import Divider from '@/components/primitives/Divider';

interface TransferTimeProps {
  formattedTime: string | null;
  arrivalStation?: string;
  departureStation?: string;
  className?: string;
}

const TransferTime: FC<TransferTimeProps> = ({
  formattedTime,
  arrivalStation,
  departureStation,
}) => {
  const isSameStation = arrivalStation === departureStation;

  return (
    <div className="my-3 flex w-full flex-row items-center px-4">
      <Divider grow className="border-neutral" />
      <div className="flex flex-row items-center gap-4 px-2">
        <Typography
          variant="body2-bold"
          className="whitespace-nowrap text-neutral-light"
        >
          {formattedTime} <TransText i18nKey="transferTime" />
        </Typography>
        {!isSameStation && (
          <div className="flex flex-row gap-1.5">
            <Typography variant="body2">{arrivalStation}</Typography>
            <Icons.transfer className="text-neutral-light" />
            <Typography variant="body2">{departureStation}</Typography>
          </div>
        )}
      </div>
      <Divider grow className="border-neutral" />
    </div>
  );
};

export default TransferTime;
