import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';
import { typographyVariants } from '@/components/primitives/Typography';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-1.5 whitespace-nowrap rounded-md ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-secondary focus-visible:ring-offset-2 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        primary:
          'border border-primary bg-primary text-white hover:bg-primary-medium active:bg-primary-dark disabled:bg-neutral disabled:text-neutral-light',
        secondary:
          'border border-primary bg-primary-light text-primary hover:bg-white active:bg-primary-pale disabled:bg-neutral-pale disabled:text-neutral',
        destructive:
          'border border-error-border bg-error-surface text-error-text hover:bg-error-surface-hover disabled:bg-neutral-pale disabled:text-neutral',
        tertiary:
          'border border-primary bg-white text-primary hover:bg-primary-pale active:bg-primary-light',
        ghost:
          'text-primary data-[state=open]:text-primary hover:text-secondary active:text-primary-dark disabled:cursor-not-allowed disabled:text-neutral disabled:hover:text-neutral',
        icon: 'rounded-full border-none text-white hover:bg-primary-dark hover:text-accent active:bg-primary active:text-accent disabled:text-neutral disabled:hover:bg-transparent',
      },
      size: {
        large: cn(typographyVariants({ variant: 'subtitle' }), 'h-14 px-6'),
        medium: cn(typographyVariants({ variant: 'body1-bold' }), 'h-12 px-6'),
        regular: cn(typographyVariants({ variant: 'button' }), 'h-10 px-6'),
        small: cn(typographyVariants({ variant: 'body2' }), 'h-8 px-4'),
      },
      fullWidth: {
        true: 'w-full',
        false: 'w-fit',
      },
      attention: {
        true: 'text-error-text data-[state=open]:text-error-text hover:text-error-border active:text-error-text',
        false: '',
      },
      strong: {
        true: typographyVariants({ variant: 'body2-bold' }),
        false: '',
      },
    },
    compoundVariants: [
      // Handling small + ghost + attention
      {
        variant: 'ghost',
        size: 'small',
        attention: true,
        className:
          'text-error-text hover:text-error-border active:text-error-text',
      },
      // Handling small + ghost + strong
      {
        variant: 'ghost',
        size: 'small',
        strong: true,
        className: cn(typographyVariants({ variant: 'body2-bold' })),
      },
      // Handling small + ghost + strong + attention
      {
        variant: 'ghost',
        size: 'small',
        strong: true,
        attention: true,
        className: cn(
          typographyVariants({ variant: 'body2-bold' }),
          'text-error-text hover:text-error-border active:text-error-text'
        ),
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'regular',
      fullWidth: false,
      attention: false,
      strong: false,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  ['data-testid']: string;
  borderless?: boolean;
  fullWidth?: boolean;
  attention?: boolean;
  strong?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      borderless = false,
      fullWidth = false,
      attention = false,
      strong = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            fullWidth,
            attention,
            strong,
            className,
          }),
          {
            'border-none': borderless,
          }
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
