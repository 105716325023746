import type { FC, ReactNode } from 'react';
import { cn } from '@/utils/cn';

interface ContentCardProps {
  children: ReactNode;
  className?: {
    root?: string;
    header?: string;
    content?: string;
  };
}

const ContentCard: FC<ContentCardProps> = ({ children, className = {} }) => {
  return (
    <div
      className={cn('flex w-full flex-col rounded-lg bg-white', className.root)}
    >
      <div id="card-content" className={cn(className.content)}>
        {children}
      </div>
    </div>
  );
};

export default ContentCard;
