import type { ReactNode } from 'react';
import React, { createContext, useState, useCallback } from 'react';
import { useDispatch } from '@/store/utils';
import {
  getInboundJourneys,
  getOutboundJourneys,
  getNonTripOffers,
  resetPurchase,
  setSearchFormValues,
} from '@/features/purchase/purchaseActions';
import type { SearchFormValues } from '@turnit-ride-ui/webshop-search-widget/widget';
import { STEP } from '@/utils/consts';
import { v4 } from 'uuid';
import { formatFullDate } from '@/utils/date-time';
import { setQueryParams } from '@turnit-ride-ui/webshop-search-widget/widget/utils/query';

export interface SearchWidgetContextType {
  isSearchOverlayOpened: boolean;
  setIsSearchOverlayOpened: (isOpened: boolean) => void;
  handleSubmit: (values: SearchFormValues) => void;
  updateAndSubmit: (updatedValues: Partial<SearchFormValues>) => void;
}

export const SearchWidgetContext = createContext<
  SearchWidgetContextType | undefined
>(undefined);

export const SearchWidgetContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isSearchOverlayOpened, setIsSearchOverlayOpened] = useState(false);
  const [currentSearchValues, setCurrentSearchValues] =
    useState<SearchFormValues | null>(null);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values: SearchFormValues) => {
      const isTicketProductType = values.productType === 'ticket';

      dispatch(
        resetPurchase({
          startStep: isTicketProductType
            ? STEP.OutboundJourney
            : STEP.NonTripOffer,
        })
      );
      setIsSearchOverlayOpened(false);

      const passengers = values.passengers.map((passenger) => ({
        ...passenger,
        // TODO: figure out how to assign actual passenger types here
        type: 'PERSON',
        externalReference: v4(),
      }));

      dispatch(
        setSearchFormValues({
          ...values,
          passengers,
        })
      );

      setCurrentSearchValues(values);

      if (!values.origin?.value || !values.destination?.value) {
        return;
      }

      if (isTicketProductType) {
        dispatch(
          getOutboundJourneys({
            originStop: { code: values.origin.value },
            destinationStop: { code: values.destination.value },
            departureTime: formatFullDate(values.departureDate),
            passengerSpecifications: [passengers[0], ...passengers.slice(1)],
            ...(values.returnDate
              ? {
                  returnSearchParameters: {
                    inwardReturnDate: formatFullDate(values.returnDate),
                  },
                }
              : {}),
          })
        );
      } else {
        dispatch(
          getNonTripOffers({
            originStop: { code: values.origin.value },
            destinationStop: { code: values.destination.value },
            validityStartTime: formatFullDate(values.departureDate),
            passengerSpecifications: [passengers[0], ...passengers.slice(1)],
          })
        );
      }

      if (values.returnDate) {
        dispatch(
          getInboundJourneys({
            originStop: { code: values.destination.value },
            destinationStop: { code: values.origin.value },
            departureTime: formatFullDate(values.returnDate),
            passengerSpecifications: [passengers[0], ...passengers.slice(1)],
            returnSearchParameters: {
              outwardTripDate: formatFullDate(values.departureDate),
            },
          })
        );
      }
    },
    [dispatch]
  );

  const updateAndSubmit = useCallback(
    (updatedValues: Partial<SearchFormValues>) => {
      if (!currentSearchValues) {
        return;
      }
      const newValues = { ...currentSearchValues, ...updatedValues };
      setQueryParams(newValues);
      handleSubmit(newValues);
    },
    [currentSearchValues, handleSubmit]
  );

  return (
    <SearchWidgetContext.Provider
      value={{
        isSearchOverlayOpened,
        setIsSearchOverlayOpened,
        handleSubmit,
        updateAndSubmit,
      }}
    >
      {children}
    </SearchWidgetContext.Provider>
  );
};
