import { createLoadingSelector } from '@/store/utils';
import {
  getInboundJourneys,
  getOutboundJourneys,
  getNonTripOffers,
  payWithExternalPayment,
  addPurchaseFlowBookingAncillary,
  removePurchaseFlowBookingAncillary,
  createPurchaseFlowTripOfferBooking,
  createPurchaseFlowNonTripOfferBooking,
  getPurchaseFlowBookingById,
  cancelPurchaseFlowBooking,
} from '@/features/purchase/purchaseActions';
import {
  initiateRefund,
  deleteRefundOffer,
  getManageFlowBookingByNumberAndEmail,
} from '@/features/manage/manageActions';
import {
  getBrandConfiguration,
  getCurrencyConfiguration,
} from '@/features/configuration/configurationActions';

export const outboundJourneysLoadingSelector =
  createLoadingSelector(getOutboundJourneys);

export const inboundJourneysLoadingSelector =
  createLoadingSelector(getInboundJourneys);

export const nonTripOffersLoadingSelector =
  createLoadingSelector(getNonTripOffers);

export const purchaseBookingLoadingSelector = createLoadingSelector(
  createPurchaseFlowTripOfferBooking,
  createPurchaseFlowNonTripOfferBooking,
  getPurchaseFlowBookingById
);

export const addAncillaryLoadingSelector = createLoadingSelector(
  addPurchaseFlowBookingAncillary
);

export const checkoutLoadingSelector = createLoadingSelector(
  addPurchaseFlowBookingAncillary,
  removePurchaseFlowBookingAncillary,
  getPurchaseFlowBookingById,
  payWithExternalPayment,
  cancelPurchaseFlowBooking
);

export const manageBookingLoadingSelector = createLoadingSelector(
  getManageFlowBookingByNumberAndEmail
);

export const successLoadingSelector = createLoadingSelector(
  getPurchaseFlowBookingById
);

export const refundLoadingSelector = createLoadingSelector(
  initiateRefund,
  deleteRefundOffer
);

export const configurationLoadingSelector = createLoadingSelector(
  getCurrencyConfiguration,
  getBrandConfiguration
);
