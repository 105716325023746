import { downloadBookingTickets } from '@/features/purchase/purchaseService';

interface UseTicketDownloadProps {
  bookingId?: string;
  onStart?: () => void;
  onFinish?: () => void;
}

export const useTicketDownload = ({
  bookingId,
  onStart,
  onFinish,
}: UseTicketDownloadProps) => {
  return async () => {
    if (!bookingId) {
      return;
    }

    try {
      onStart?.();
      await downloadBookingTickets(bookingId);
    } finally {
      onFinish?.();
    }
  };
};
