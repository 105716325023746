import { useSelector } from '@/store/utils';
import { purchaseFlowBookingSelector } from '@/features/purchase/purchaseSelectors';
import { manageFlowBookingSelector } from '@/features/manage/manageSelectors';
import type { Flow } from '@/types/booking';

export const useBooking = (flow: Flow) => {
  const purchaseBooking = useSelector(purchaseFlowBookingSelector);
  const manageBooking = useSelector(manageFlowBookingSelector);

  return flow === 'purchase' ? purchaseBooking : manageBooking;
};
