import { createSelector, selector } from '@/store/utils';

const selectManageFlowBooking = selector((state) => state.manage.booking);
export const manageFlowBookingSelector = createSelector(
  selectManageFlowBooking
);

const selectRefundSelectedAdmissions = selector(
  (state) => state.manage.refund.selectedAdmissions
);
export const refundSelectedAdmissionsSelector = createSelector(
  selectRefundSelectedAdmissions
);

const selectRefundDetails = selector((state) => state.manage.refund.details);
export const refundDetailsSelector = createSelector(selectRefundDetails);
