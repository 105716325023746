interface TicketCuticleLineProps {
  isVertical: boolean;
}

const TicketCuticleLine = ({ isVertical }: TicketCuticleLineProps) => {
  return (
    <svg
      className={`absolute ${
        isVertical
          ? '-left-[3px] top-3 h-[calc(100%-24px)]'
          : '-top-[3px] left-3 w-[calc(100%-24px)]'
      }`}
      viewBox={isVertical ? '0 0 2 56' : '0 0 300 2'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d={isVertical ? 'M1 56L1 0' : 'M0 1H300 '}
        stroke="#C2C8D6"
        strokeWidth={1}
        strokeDasharray="8 4"
      />
    </svg>
  );
};

export default TicketCuticleLine;
