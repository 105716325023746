import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { formatDay, formatYear } from '@/utils/date-time';
import type { MultiRideAccountDTO } from '@/types/dto';

interface MultiRideAccountValidityProps {
  travelAccount: MultiRideAccountDTO;
}

const MultiRideAccountValidity: FC<MultiRideAccountValidityProps> = ({
  travelAccount,
}) => {
  return (
    <div className="flex w-full flex-col gap-1">
      <div className="flex w-full justify-between">
        <div
          className="flex items-center gap-2"
          data-testid="multi-ride-valid-from-label"
        >
          <Typography
            variant="body2"
            className="w-[70px] text-nowrap text-neutral"
          >
            <TransText i18nKey="validFrom" />:
          </Typography>
          <div className="flex gap-1" data-testid="multi-ride-valid-from-value">
            <Typography variant="body2-bold" className="text-nowrap">
              {formatDay(travelAccount.validFrom)},
            </Typography>
            <Typography variant="body2">
              {formatYear(travelAccount.validFrom)}
            </Typography>
          </div>
        </div>

        <div
          className="flex items-center gap-1.5"
          data-testid="multi-ride-remaining-uses-label"
        >
          <Typography variant="body2" className="text-nowrap text-neutral">
            <TransText i18nKey="remainingUses" />:
          </Typography>
          <div
            data-testid="multi-ride-remaining-uses-value"
            className="flex items-center"
          >
            <Typography variant="body2-bold">
              {travelAccount.balance.remaining}
            </Typography>
            <Typography variant="body2">
              /{travelAccount.balance.total}
            </Typography>
          </div>
        </div>
      </div>

      <div
        className="flex items-center gap-2"
        data-testid="multi-ride-valid-to-label"
      >
        <Typography
          variant="body2"
          className="w-[70px] text-nowrap text-neutral"
        >
          <TransText i18nKey="validTo" />:
        </Typography>
        <div className="flex gap-1" data-testid="multi-ride-valid-to-value">
          <Typography variant="body2-bold" className="text-nowrap">
            {formatDay(travelAccount.validUntil)},
          </Typography>
          <Typography variant="body2">
            {formatYear(travelAccount.validUntil)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default MultiRideAccountValidity;
