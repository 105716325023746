import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';

interface ProductDetailsProps {
  passengerCount: number;
  description: string | undefined;
  admissionsAmount: number;
  isAllRefunded: boolean;
}

const ProductDetails: FC<ProductDetailsProps> = ({
  passengerCount,
  description,
  admissionsAmount,
  isAllRefunded,
}) => {
  const currency = useSelector(currencySelector);

  return (
    <div className="flex flex-row justify-between laptop:items-end laptop:pb-0">
      <div className="flex items-center gap-2">
        <Typography variant="body1-bold">{description}</Typography>
        <Typography variant="body2" className="text-neutral">
          <TransText
            i18nKey="passengersCount"
            values={{ count: passengerCount }}
          />
        </Typography>
      </div>
      {!isAllRefunded && (
        <Typography variant="body1-bold" className="text-primary">
          {admissionsAmount}
          {currency.symbol}
        </Typography>
      )}
    </div>
  );
};

export default ProductDetails;
