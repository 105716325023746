import type { Dispatch, FC, SetStateAction } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';
import { Icons } from '@/components/icons';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';
import { useFormContext } from 'react-hook-form';
import Divider from '@/components/primitives/Divider';

interface RegularAncillaryModalFooterProps {
  totalPrice: number;
  setIsOverlayOpened: Dispatch<SetStateAction<boolean>>;
}

const RegularAncillaryModalFooter: FC<RegularAncillaryModalFooterProps> = ({
  totalPrice,
  setIsOverlayOpened,
}) => {
  const currency = useSelector(currencySelector);
  const { watch } = useFormContext();
  const selectedLegId = watch('legId');

  return (
    <div className="w-full">
      <Divider className="border-neutral-light" />
      <div className="flex w-full items-center justify-between px-6 py-3">
        <div className="flex gap-2">
          <Icons.travelPass className="h-5 w-5 text-primary" />
          <Typography variant="body1" className="text-neutral">
            <TransText i18nKey="totalPrice" />:
          </Typography>
          <Typography variant="subtitle" className="text-primary">
            {totalPrice}
            {currency.symbol}
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="tertiary"
            data-testid="ancillary-selection-cancel-button"
            className="w-1/2 rounded-lg laptop:w-auto"
            onClick={() => setIsOverlayOpened(false)}
            type="button"
          >
            <Typography variant="button">
              <TransText i18nKey="cancel" />
            </Typography>
          </Button>
          <Button
            data-testid="ancillary-selection-submit-button"
            className="w-1/2 gap-2 rounded-lg p-4 laptop:w-auto"
            disabled={!selectedLegId}
            type="submit"
          >
            <Icons.check className="h-4 w-4" />
            <Typography variant="button">
              {selectedLegId ? (
                <TransText i18nKey="confirm" />
              ) : (
                <TransText i18nKey="selectLeg" />
              )}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegularAncillaryModalFooter;
