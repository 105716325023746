import React, { type FC, type ReactNode, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/primitives/Dialog';
import { Button } from '@/components/primitives/Button';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { cn } from '@turnit-ride-ui/webshop-search-widget/widget/utils/cn';

interface ConfirmModalProps {
  onConfirm: () => void;
  children: ReactNode;
  title?: ReactNode;
  description: ReactNode;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  onConfirm,
  title,
  description,
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={() => setOpen(true)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader
          className={cn('p-0', { 'border-none': !title })}
          showClose={false}
        >
          <DialogTitle className="px-6 py-4 text-primary" hidden={!title}>
            {title}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-6 py-4" asChild>
          <Typography variant="body1" className="text-dark">
            {description}
          </Typography>
        </DialogDescription>
        <DialogFooter>
          <Button
            data-testid="confirm-modal-cancel"
            variant="tertiary"
            onClick={() => setOpen(false)}
          >
            <Typography variant="button">
              <TransText i18nKey="cancel" />
            </Typography>
          </Button>
          <Button data-testid="confirm-modal-action" onClick={onConfirm}>
            <Typography variant="button">
              <TransText i18nKey="confirm" />
            </Typography>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
