import getApiInstance from '@/api';
import type {
  DecoratedTravelPassDetails,
  TravelPassDetails,
} from '@/types/manage';

interface FetchTravelPassDetailsPayload extends TravelPassDetails {
  controlNumber: NonNullable<TravelPassDetails['controlNumber']>;
  issuer: NonNullable<TravelPassDetails['issuer']>;
}

export const fetchTravelPassDetails = async (
  details: Array<TravelPassDetails>
): Promise<Array<DecoratedTravelPassDetails>> => {
  const api = (await getApiInstance()).agentApi;

  const travelAccounts = await Promise.all(
    details
      .filter((item): item is FetchTravelPassDetailsPayload =>
        Boolean(item.controlNumber && item.issuer)
      )
      .map(({ controlNumber, issuer }) =>
        api
          .TravelAccounts_GetTravelAccount({
            Issuer: issuer,
            TravelAccount: controlNumber,
            Embed: 'ALL',
          })
          .then((res) => res.data.travelAccount)
      )
  );

  return details.map((el, i) => ({ ...el, travelAccount: travelAccounts[i] }));
};
