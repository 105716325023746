import { type FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/primitives/Dialog';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';

interface ExpiryWarningDialogProps {
  open: boolean;
  onClose: () => void;
  timeTillExpiry: string;
}

const ExpiryWarningDialog: FC<ExpiryWarningDialogProps> = ({
  open,
  onClose,
  timeTillExpiry,
}) => {
  const [minutes, seconds] = timeTillExpiry.split(':');

  const parsedMinutes = parseInt(minutes);
  const parsedSeconds = parseInt(seconds);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className="tablet:w-[480px]"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader className="border-none" showClose={false}>
          <DialogTitle>
            <div className="flex flex-row items-center gap-2">
              <Icons.infoCircle />
              <Typography variant="body1" className="font-bold">
                <TransText i18nKey="sessionAboutToExpire" />
              </Typography>
            </div>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="px-6 py-3">
          <Typography className="text-dark">
            <TransText i18nKey="bookingSessionExpiresIn" />:
          </Typography>
          <Typography className="text-dark">
            <div className="bg-yellow flex w-1/4 items-center gap-1">
              <Typography className="flex items-center gap-0.5">
                <span className="inline-block w-4 text-center">
                  {parsedMinutes}
                </span>
                <span>
                  <TransText
                    i18nKey="minutesCount"
                    values={{ count: parsedMinutes }}
                  />
                </span>
              </Typography>
              <Typography className="flex items-center gap-0.5">
                <span className="inline-block w-4 text-center">
                  {parsedSeconds}
                </span>
                <span>
                  <TransText
                    i18nKey="secondsCount"
                    values={{ count: parsedSeconds }}
                  />
                </span>
              </Typography>
            </div>
          </Typography>
        </DialogDescription>
        <DialogFooter className="items-end">
          <Button
            variant="tertiary"
            data-testid="expiry-warning-dismissal-button"
            className="rounded-lg"
            onClick={onClose}
          >
            <TransText i18nKey="dismissWarning" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ExpiryWarningDialog;
