import { type FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import TravelPassDetails from '@/components/manage/travel-passes/TravelPassDetails';
import { useBookingNonTripOffers } from '@/hooks/useBookingNonTripOffers';
import { Flow } from '@/types/booking';

interface TravelPassDetailsProps {}

const TravelPasses: FC<TravelPassDetailsProps> = () => {
  const nonTripOffers = useBookingNonTripOffers(Flow.manageBooking);

  return (
    <div className="flex flex-1 flex-col">
      <Typography variant="heading2" className="mb-6 mt-4">
        <TransText i18nKey="bookedTravelPasses" />
      </Typography>
      {nonTripOffers.map((data, key) => (
        <TravelPassDetails data={data} key={key} />
      ))}
    </div>
  );
};

export default TravelPasses;
