import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/utils/cn';
import type { ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';

const typographyVariants = cva('font-[500] tracking-[0px]', {
  variants: {
    variant: {
      caption: 'text-[10px] leading-4',
      title: 'text-[32px] font-bold leading-10',
      heading1: 'text-[24px] font-bold leading-8',
      heading2: 'text-[20px] font-bold leading-7',
      'heading2-light': 'text-[20px] leading-7',
      subtitle: 'text-base font-bold leading-5',
      paragraph: 'text-base',
      'paragraph-link': 'text-base underline',
      button: 'text-[14px] leading-5 -tracking-[0.14px]',
      body1: 'text-[14px] -tracking-[0.28px]',
      'body1-bold': 'text-[14px] font-bold leading-5 -tracking-[0.14px]',
      'body1-link': 'text-[14px] leading-5 -tracking-[0.28px] underline',
      body2: 'text-[12px] leading-4 -tracking-[0.24px]',
      'body2-bold':
        'text-[12px] font-bold leading-3 leading-4 -tracking-[0.12px]',
      'body2-link': 'text-[12px] leading-4 -tracking-[0.24px] underline',
      label: 'text-[10px] leading-3 text-neutral-light',
      times: 'text-[22px] font-extrabold leading-8 -tracking-[1px]',
      'mobile-title': 'text-[28px] font-bold leading-9',
    },
  },
});

export interface TypographyProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof typographyVariants> {
  asChild?: boolean;
  children?: ReactNode;
}

const Typography = React.forwardRef<HTMLDivElement, TypographyProps>(
  ({ className, variant, children, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'p';
    return (
      <Comp
        className={cn(typographyVariants({ variant, className }))}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    );
  }
);

Typography.displayName = 'Typography';

export { Typography, typographyVariants };
