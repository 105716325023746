import { type FC, useCallback, useEffect } from 'react';
import ToggleableRow from '@/components/purchase/checkout/ToggleableRow';
import type { UseFormReturn } from 'react-hook-form';
import type { AncillaryValues, PassengerValues } from '@/utils/zod/schema';
import { FormControl, FormItem } from '@/components/primitives/Form';
import { TransText } from '@/i18n/trans/text';
import { Typography } from '@/components/primitives/Typography';
import { getPassengerName, getPassengerOtherDetails } from '@/utils/passenger';
import type { AdditionalOfferItem } from '@/types/offer';
import { Checkbox } from '@/components/primitives/Checkbox';
import { useTranslation } from 'react-i18next';

interface PassengerOptionsSubrowsProps {
  ancillaryId: NonNullable<AdditionalOfferItem['id']>;
  passengerListForm: UseFormReturn<{
    passengers: Array<PassengerValues>;
  }>;
  ancillaryListForm: UseFormReturn<AncillaryValues>;
  renderAllAncillaries: boolean;
}

const PassengerOptions: FC<PassengerOptionsSubrowsProps> = ({
  ancillaryId,
  passengerListForm,
  ancillaryListForm,
  renderAllAncillaries,
}) => {
  const allPassengers = passengerListForm.watch('passengers');
  const selectedAncillaries = ancillaryListForm.watch('selectedAncillaries');
  const ancillaryIndex = selectedAncillaries.findIndex(
    (ancillary) => ancillary?.id === ancillaryId
  );
  const { t } = useTranslation();

  const handlePassengerToggle = useCallback(
    (checked: boolean, passenger: PassengerValues) => {
      const currentRefs = ancillaryListForm.getValues(
        `selectedAncillaries.${ancillaryIndex}.passengersExternalReferences`
      );

      if (checked) {
        ancillaryListForm.setValue(
          `selectedAncillaries.${ancillaryIndex}.passengersExternalReferences`,
          [...currentRefs, passenger.externalReference]
        );
      } else {
        const newRefs = currentRefs.filter(
          (ref) => ref !== passenger.externalReference
        );

        if (
          newRefs.length === 0 &&
          !renderAllAncillaries &&
          ancillaryIndex > 4
        ) {
          const filteredAncillaries = selectedAncillaries.filter(
            (ancillary) => ancillary?.id !== ancillaryId
          );
          ancillaryListForm.setValue(
            'selectedAncillaries',
            filteredAncillaries
          );
        } else {
          ancillaryListForm.setValue(
            `selectedAncillaries.${ancillaryIndex}.passengersExternalReferences`,
            newRefs
          );
        }
      }
    },
    [
      ancillaryId,
      ancillaryIndex,
      ancillaryListForm,
      renderAllAncillaries,
      selectedAncillaries,
    ]
  );

  useEffect(() => {
    if (allPassengers.length === 1) {
      ancillaryListForm.setValue(
        `selectedAncillaries.${ancillaryIndex}.passengersExternalReferences`,
        [allPassengers[0].externalReference]
      );
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="w-full self-end pl-8">
      {allPassengers?.map((passenger, passengerIndex) => {
        const isActive = Boolean(
          selectedAncillaries[
            ancillaryIndex
          ]?.passengersExternalReferences?.includes(passenger.externalReference)
        );

        if (ancillaryIndex > 4 && !renderAllAncillaries && !isActive) {
          return null;
        }

        return (
          <FormItem key={passenger.id}>
            <FormControl
              hideLabel
              label={<TransText i18nKey="selectPassengers" />}
            >
              <ToggleableRow
                isActive={isActive}
                onClick={() => handlePassengerToggle(!isActive, passenger)}
              >
                <div className="flex w-full items-center gap-2">
                  <Checkbox
                    checked={isActive}
                    onCheckedChange={(checked) =>
                      handlePassengerToggle(checked === true, passenger)
                    }
                    className="h-4 w-4"
                    aria-label={t('text.selectPassenger', 'Select passenger')}
                  />
                  <div className="flex flex-col">
                    <Typography variant="body2-bold">
                      {getPassengerName(passenger, passengerIndex)}
                    </Typography>
                    <Typography variant="label" className="text-neutral-light">
                      {getPassengerOtherDetails(passenger)}
                    </Typography>
                  </div>
                </div>
              </ToggleableRow>
            </FormControl>
          </FormItem>
        );
      })}
    </div>
  );
};

export default PassengerOptions;
