import type { FC } from 'react';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import type { Flow } from '@/types/booking';
import HighlightedRow from '@/components/booking-summary/HighlightedRow';
import { useBookingNonTripOffers } from '@/hooks/useBookingNonTripOffers';
import { format } from 'date-fns';

interface NonTripOfferDescriptionProps {
  flow: Flow;
}

const NonTripOfferDescription: FC<NonTripOfferDescriptionProps> = ({
  flow,
}) => {
  const nonTripOffers = useBookingNonTripOffers(flow);

  return (
    <div className="flex flex-col">
      <HighlightedRow className="rounded-t-lg">
        <div className="flex gap-2">
          <Icons.transportFrom className="h-5 w-5 text-primary" />
          <Typography variant="body1-bold">
            <TransText i18nKey="travelPassDetails" />
          </Typography>
        </div>
      </HighlightedRow>
      <div className="flex flex-col gap-2 px-4 py-3">
        {nonTripOffers.map((offer, key) => {
          const zone =
            offer.travelAccount?.regionalConstraint?.regionalValidities[0].zone;
          const origin = zone?.entryStation?.name;
          const destination = zone?.terminalStation?.name;

          return (
            <div className="flex flex-col" key={key}>
              <div className="flex flex-row gap-1">
                <Typography variant="body1-bold">{origin}</Typography>
                <Typography variant="body1-bold">-</Typography>
                <Typography variant="body1-bold">{destination}</Typography>
              </div>
              <div>
                <div className="flex gap-1">
                  <Typography className="text-neutral" variant="body1">
                    <TransText i18nKey="activation" />
                    {':'}
                  </Typography>
                  {offer.validFrom && (
                    <Typography variant="body1-bold">
                      {format(offer.validFrom, 'dd MMM, yyyy')}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NonTripOfferDescription;
