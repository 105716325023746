import type { HTMLAttributes } from 'react';

type IconProps = HTMLAttributes<SVGElement> & {
  width?: number;
  height?: number;
};

const getIconSizeProps = (
  defaultWidth: number,
  defaultHeight: number,
  { width, height, ...rest }: IconProps
) => ({
  width: width || defaultWidth,
  height: height || defaultHeight,
  // this styling won't allow the icon to be squeezed smaller than its width and height
  // style: {
  //   minWidth: width || defaultWidth,
  //   minHeight: height || defaultHeight,
  // },
  ...rest,
});

export const Icons = {
  addCircle: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12C4 16.4183 7.58172 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
        fill="currentColor"
      />
      <path
        d="M12 18C12.5523 18 13 17.5523 13 17V13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13V7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11V17C11 17.5523 11.4477 18 12 18Z"
        fill="currentColor"
      />
    </svg>
  ),
  airConditioning: (props: IconProps) => (
    <svg viewBox="0 0 14 14" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00001 0.333008C7.3682 0.333008 7.66668 0.631485 7.66668 0.999674V2.19967C7.66668 2.56786 7.3682 2.86634 7.00001 2.86634C6.63182 2.86634 6.33334 2.56786 6.33334 2.19967V0.999674C6.33334 0.631485 6.63182 0.333008 7.00001 0.333008ZM2.32861 2.32827C2.58896 2.06792 3.01107 2.06792 3.27141 2.32827L3.87141 2.92827C4.13176 3.18862 4.13176 3.61073 3.87141 3.87108C3.61107 4.13143 3.18896 4.13143 2.92861 3.87108L2.32861 3.27108C2.06826 3.01073 2.06826 2.58862 2.32861 2.32827ZM7.00001 4.66634C5.71135 4.66634 4.66668 5.71101 4.66668 6.99967C4.66668 8.28834 5.71135 9.33301 7.00001 9.33301C7.3682 9.33301 7.66668 9.63148 7.66668 9.99967C7.66668 10.3679 7.3682 10.6663 7.00001 10.6663C4.97497 10.6663 3.33334 9.02472 3.33334 6.99967C3.33334 4.97463 4.97497 3.33301 7.00001 3.33301C7.3682 3.33301 7.66668 3.63148 7.66668 3.99967C7.66668 4.36786 7.3682 4.66634 7.00001 4.66634ZM0.333344 6.99967C0.333344 6.63148 0.63182 6.33301 1.00001 6.33301H2.20001C2.5682 6.33301 2.86668 6.63148 2.86668 6.99967C2.86668 7.36786 2.5682 7.66634 2.20001 7.66634H1.00001C0.63182 7.66634 0.333344 7.36786 0.333344 6.99967ZM3.87141 10.1283C4.13176 10.3886 4.13176 10.8107 3.87141 11.0711L3.27141 11.6711C3.01107 11.9314 2.58895 11.9314 2.32861 11.6711C2.06826 11.4107 2.06826 10.9886 2.32861 10.7283L2.92861 10.1283C3.18896 9.86792 3.61107 9.86792 3.87141 10.1283ZM7.00001 11.133C7.3682 11.133 7.66668 11.4315 7.66668 11.7997V12.9997C7.66668 13.3679 7.3682 13.6663 7.00001 13.6663C6.63182 13.6663 6.33334 13.3679 6.33334 12.9997V11.7997C6.33334 11.4315 6.63182 11.133 7.00001 11.133Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00001 0.333008C7.3682 0.333008 7.66668 0.631485 7.66668 0.999674V12.9997C7.66668 13.3679 7.3682 13.6663 7.00001 13.6663C6.63182 13.6663 6.33334 13.3679 6.33334 12.9997V0.999674C6.33334 0.631485 6.63182 0.333008 7.00001 0.333008Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7.66634H7.00001V6.33301H13C13.3682 6.33301 13.6667 6.63148 13.6667 6.99967C13.6667 7.36786 13.3682 7.66634 13 7.66634Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1684 1.894C10.4108 2.1711 10.3828 2.59227 10.1057 2.83473L7.43901 5.16806L6.56101 4.16462L9.22767 1.83129C9.50477 1.58884 9.92594 1.61691 10.1684 1.894Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.165 3.8901C12.4096 4.16529 12.3848 4.58667 12.1096 4.83128L9.10959 7.49795L8.22377 6.5014L11.2238 3.83474C11.499 3.59012 11.9203 3.61491 12.165 3.8901Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22377 7.49795L11.2238 10.1646C11.499 10.4092 11.9203 10.3844 12.165 10.1093C12.4096 9.83406 12.3848 9.41268 12.1096 9.16807L9.10959 6.5014L8.22377 7.49795Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56101 9.83473L9.22767 12.1681C9.50477 12.4105 9.92594 12.3824 10.1684 12.1053C10.4108 11.8283 10.3828 11.4071 10.1057 11.1646L7.43901 8.83129L6.56101 9.83473Z"
        fill="currentColor"
      />
    </svg>
  ),
  arrowDown: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929L12 17.5858L18.2929 11.2929C18.6834 10.9024 19.3166 10.9024 19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.5196 19.8946 12.2652 20 12 20C11.7348 20 11.4804 19.8946 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929Z"
        fill="currentColor"
      />
    </svg>
  ),
  arrowLeft: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L6.41421 12L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
  arrowRight: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C19.8947 11.4804 20 11.7348 20 12C20 12.2652 19.8947 12.5196 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L17.5858 12L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
  bed: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...getIconSizeProps(24, 24, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6C1 4.89543 1.89543 4 3 4H21C22.1046 4 23 4.89543 23 6V18C23 19.1046 22.1046 20 21 20H3C1.89543 20 1 19.1046 1 18V6ZM17 18H21V17H17V18ZM17 15H21V9H17V15ZM15.3234 7.90917C15.6802 7.36182 16.2979 7 17 7H21V6L13.4142 6L15.3234 7.90917ZM10 6L3 6V18H15V11H12C10.8954 11 10 10.1046 10 9V6ZM13.5858 9L12 7.41421V9H13.5858Z"
        fill="currentColor"
      />
    </svg>
  ),
  bicycle: (props: IconProps) => (
    <svg viewBox="0 0 16 16" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66675 12.6667C4.58722 12.6667 5.33342 11.9205 5.33342 11C5.33342 10.0795 4.58722 9.33333 3.66675 9.33333C2.74627 9.33333 2.00008 10.0795 2.00008 11C2.00008 11.9205 2.74627 12.6667 3.66675 12.6667ZM3.66675 14C5.3236 14 6.66675 12.6569 6.66675 11C6.66675 9.34315 5.3236 8 3.66675 8C2.00989 8 0.666748 9.34315 0.666748 11C0.666748 12.6569 2.00989 14 3.66675 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0001 12.6667C11.9206 12.6667 12.6667 11.9205 12.6667 11C12.6667 10.0795 11.9206 9.33333 11.0001 9.33333C10.0796 9.33333 9.33342 10.0795 9.33342 11C9.33342 11.9205 10.0796 12.6667 11.0001 12.6667ZM11.0001 14C12.6569 14 14.0001 12.6569 14.0001 11C14.0001 9.34315 12.6569 8 11.0001 8C9.34323 8 8.00008 9.34315 8.00008 11C8.00008 12.6569 9.34323 14 11.0001 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33342 2.66667C5.33342 2.29848 5.63189 2 6.00008 2L8.76666 2L11.6093 8.39591L10.3909 8.93743L7.90017 3.33333H6.00008C5.63189 3.33333 5.33342 3.03486 5.33342 2.66667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33341 5.33333L4.33341 8.66667H3.00008L3.00008 5.33333H4.33341Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00008 4.66667C2.00008 4.29848 2.29856 4 2.66675 4L4.66675 4C5.03494 4 5.33342 4.29848 5.33342 4.66667C5.33342 5.03486 5.03494 5.33333 4.66675 5.33333H2.66675C2.29856 5.33333 2.00008 5.03486 2.00008 4.66667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 7.33333L3.66675 7.33333L3.66675 6L10.0001 6V7.33333Z"
        fill="currentColor"
      />
    </svg>
  ),
  burgerMenu: (props: IconProps) => (
    <svg viewBox="0 0 24 24" {...getIconSizeProps(24, 24, props)}>
      <title>Menu</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Menu">
          <rect
            id="Rectangle"
            fillRule="nonzero"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
          <line
            x1="5"
            y1="7"
            x2="19"
            y2="7"
            id="Path"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
          ></line>
          <line
            x1="5"
            y1="17"
            x2="19"
            y2="17"
            id="Path"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
          ></line>
          <line
            x1="5"
            y1="12"
            x2="19"
            y2="12"
            id="Path"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
          ></line>
        </g>
      </g>
    </svg>
  ),
  bus: (props: IconProps) => (
    <svg viewBox="0 0 12 14" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 1.66671L1.33333 1.66671L1.33333 11L10.6667 11L10.6667 1.66671ZM1.33333 0.333374C0.596954 0.333374 0 0.930328 0 1.66671L0 11C0 11.7364 0.596954 12.3334 1.33333 12.3334L10.6667 12.3334C11.403 12.3334 12 11.7364 12 11L12 1.66671C12 0.930328 11.403 0.333374 10.6667 0.333374L1.33333 0.333374Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0.333374C2.36819 0.333374 2.66667 0.631851 2.66667 1.00004L2.66667 3.00004L9.33333 3.00004V1.00004C9.33333 0.631851 9.63181 0.333374 10 0.333374C10.3682 0.333374 10.6667 0.631851 10.6667 1.00004V4.33337L1.33333 4.33337L1.33333 1.00004C1.33333 0.631851 1.63181 0.333374 2 0.333374Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.33337C0 7.96518 0.298477 7.66671 0.666667 7.66671L11.3333 7.66671C11.7015 7.66671 12 7.96518 12 8.33337C12 8.70156 11.7015 9.00004 11.3333 9.00004L0.666667 9.00004C0.298477 9.00004 0 8.70156 0 8.33337Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66667 11C2.21895 11 2.66667 11.4478 2.66667 12V12.6667C2.66667 13.219 2.21895 13.6667 1.66667 13.6667C1.11438 13.6667 0.666667 13.219 0.666667 12.6667L0.666667 12C0.666667 11.4478 1.11438 11 1.66667 11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3333 11C10.8856 11 11.3333 11.4478 11.3333 12V12.6667C11.3333 13.219 10.8856 13.6667 10.3333 13.6667C9.78105 13.6667 9.33333 13.219 9.33333 12.6667V12C9.33333 11.4478 9.78105 11 10.3333 11Z"
        fill="currentColor"
      />
    </svg>
  ),
  buyReturnTicket: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...getIconSizeProps(20, 20, props)}
    >
      <path
        d="M9.45841 4.16664H16.6667C17.1439 4.16135 17.6004 4.36084 17.9207 4.71458C18.241 5.06831 18.3943 5.54236 18.3417 6.01664L17.6917 11.85C17.5979 12.6982 16.8785 13.3385 16.0251 13.3333H9.04174C8.22508 13.3333 7.63341 12.5 7.50008 11.6666H16.0251L16.3084 9.16664L11.6667 9.16664C11.2065 9.16664 10.8334 8.79354 10.8334 8.3333C10.8334 7.87307 11.2065 7.49997 11.6667 7.49997L16.4834 7.49997L16.6667 5.8333L8.04174 5.8333L7.62508 3.3333H5.83341V1.66664L7.62508 1.66664C8.44176 1.66361 9.14033 2.25281 9.27508 3.0583L9.45841 4.16664Z"
        fill="currentColor"
      />
      <path
        d="M5.59174 10.2416L4.50841 9.16664H8.33341C8.79365 9.16664 9.16674 8.79354 9.16674 8.3333C9.16674 7.87307 8.79365 7.49997 8.33341 7.49997H4.50841L5.59174 6.42497C5.91851 6.0982 5.91851 5.56841 5.59174 5.24164C5.26497 4.91487 4.73518 4.91487 4.40841 5.24164L1.90841 7.74164C1.83254 7.82089 1.77307 7.91434 1.73341 8.01664C1.65006 8.21952 1.65006 8.44709 1.73341 8.64997C1.77307 8.75226 1.83254 8.84572 1.90841 8.92497L4.40841 11.425C4.56488 11.5827 4.77788 11.6715 5.00008 11.6715C5.22227 11.6715 5.43527 11.5827 5.59174 11.425C5.7495 11.2685 5.83824 11.0555 5.83824 10.8333C5.83824 10.6111 5.7495 10.3981 5.59174 10.2416Z"
        fill="currentColor"
      />
      <path
        d="M8.33341 16.6666C8.33341 17.5871 7.58722 18.3333 6.66674 18.3333C5.74627 18.3333 5.00008 17.5871 5.00008 16.6666C5.00008 15.7462 5.74627 15 6.66674 15C7.58722 15 8.33341 15.7462 8.33341 16.6666Z"
        fill="currentColor"
      />
      <path
        d="M17.5001 16.6666C17.5001 17.5871 16.7539 18.3333 15.8334 18.3333C14.9129 18.3333 14.1667 17.5871 14.1667 16.6666C14.1667 15.7462 14.9129 15 15.8334 15C16.7539 15 17.5001 15.7462 17.5001 16.6666Z"
        fill="currentColor"
      />
    </svg>
  ),
  calendar: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 6H5V20H19V6ZM5 4H19C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V6C3 4.89543 3.89543 4 5 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2C16.5523 2 17 2.44772 17 3V7C17 7.55229 16.5523 8 16 8C15.4477 8 15 7.55229 15 7V3C15 2.44772 15.4477 2 16 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.55228 2 9 2.44772 9 3V7C9 7.55229 8.55228 8 8 8C7.44772 8 7 7.55229 7 7V3C7 2.44772 7.44772 2 8 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 10C3 9.44771 3.44772 9 4 9H20C20.5523 9 21 9.44771 21 10C21 10.5523 20.5523 11 20 11H4C3.44772 11 3 10.5523 3 10Z"
        fill="currentColor"
      />
    </svg>
  ),
  cancel: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34315 17.6568C9.46734 20.781 14.5327 20.781 17.6569 17.6568C20.781 14.5326 20.781 9.46731 17.6569 6.34312C14.5327 3.21892 9.46734 3.21892 6.34315 6.34311C3.21895 9.46731 3.21895 14.5326 6.34315 17.6568ZM4.92893 19.071C8.83417 22.9763 15.1658 22.9763 19.0711 19.071C22.9763 15.1658 22.9763 8.83414 19.0711 4.9289C15.1658 1.02366 8.83418 1.02366 4.92893 4.9289C1.02369 8.83414 1.02369 15.1658 4.92893 19.071Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2426 7.75733C16.6332 8.14785 16.6332 8.78102 16.2426 9.17154L9.17157 16.2426C8.78105 16.6331 8.14788 16.6331 7.75736 16.2426C7.36683 15.8521 7.36683 15.2189 7.75736 14.8284L14.8284 7.75733C15.219 7.3668 15.8521 7.3668 16.2426 7.75733Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75736 7.75733C8.14788 7.3668 8.78105 7.3668 9.17157 7.75733L16.2426 14.8284C16.6332 15.2189 16.6332 15.8521 16.2426 16.2426C15.8521 16.6331 15.219 16.6331 14.8284 16.2426L7.75736 9.17154C7.36683 8.78102 7.36683 8.14785 7.75736 7.75733Z"
        fill="currentColor"
      />
    </svg>
  ),
  carriage: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
      {...getIconSizeProps(24, 24, props)}
    >
      <path d="M2.5 3H3.5V5H2.5V3Z" fill="currentColor" />
      <path d="M5.5 3H4.5V7.5H5.5V3Z" fill="currentColor" />
      <path d="M6.5 3H7.5V7.5H6.5V3Z" fill="currentColor" />
      <path d="M9.5 3H8.5V5H9.5V3Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1.5C1.17157 1.5 0.5 2.21954 0.5 3.10714V9H1C1 9.55229 1.44772 10 2 10H0.5V11L11.5 11V10L10 10C10.5523 10 11 9.55229 11 9H11.5V3.10714C11.5 2.21954 10.8284 1.5 10 1.5H2ZM1.5 3.10714C1.5 2.81128 1.72386 2.57143 2 2.57143H10C10.2761 2.57143 10.5 2.81128 10.5 3.10714V7.92857H1.5V3.10714ZM10 10C9.44771 10 9 9.55229 9 9H3C3 9.55229 2.55228 10 2 10H10Z"
        fill="currentColor"
      />
    </svg>
  ),
  check: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
  checkCircle: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.256 4.69258C13.6802 3.99045 11.9197 3.81651 10.237 4.1967C8.55423 4.57689 7.03948 5.49084 5.91862 6.80224C4.79777 8.11364 4.13085 9.75223 4.01734 11.4736C3.90383 13.195 4.34981 14.907 5.28877 16.3542C6.22772 17.8015 7.60935 18.9064 9.22758 19.5042C10.8458 20.1021 12.6139 20.1608 14.2683 19.6717C15.9226 19.1826 17.3745 18.1718 18.4075 16.79C19.4404 15.4083 19.999 13.7297 20 12.0046L20.0006 11.0046L22.0006 11.0057L22 12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69278 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572L16.9834 3.27272L16.1694 5.09958L15.256 4.69258Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7068 3.29254C23.0975 3.68287 23.0978 4.31603 22.7075 4.70675L12.7075 14.7168C12.52 14.9044 12.2656 15.0099 12.0003 15.01C11.7349 15.0101 11.4805 14.9047 11.2929 14.7171L8.29289 11.7171C7.90237 11.3266 7.90237 10.6934 8.29289 10.3029C8.68342 9.91237 9.31658 9.91237 9.70711 10.3029L11.9996 12.5954L21.2925 3.29325C21.6829 2.90253 22.316 2.90221 22.7068 3.29254Z"
        fill="currentColor"
      />
    </svg>
  ),
  chevronDown: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
  chevronLeft: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
  chevronRight: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
  chevronUp: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
  circle: (props: IconProps) => (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...getIconSizeProps(24, 24, props)}
    >
      <circle cx="12" cy="12" r="10" />
    </svg>
  ),
  coin: (props: IconProps) => (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...getIconSizeProps(20, 20, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1178 7.83177C13.1825 7.29943 13.3334 6.81069 13.3334 6.66732C13.3334 6.52395 13.1825 6.03521 12.1178 5.50286C11.1329 5.0104 9.67888 4.66732 8.00004 4.66732C6.3212 4.66732 4.86722 5.0104 3.88228 5.50286C2.81759 6.03521 2.66671 6.52395 2.66671 6.66732C2.66671 6.81069 2.81759 7.29943 3.88228 7.83177C4.86722 8.32424 6.3212 8.66732 8.00004 8.66732C9.67888 8.66732 11.1329 8.32424 12.1178 7.83177ZM8.00004 10.0007C11.6819 10.0007 14.6667 8.50827 14.6667 6.66732C14.6667 4.82637 11.6819 3.33398 8.00004 3.33398C4.31814 3.33398 1.33337 4.82637 1.33337 6.66732C1.33337 8.50827 4.31814 10.0007 8.00004 10.0007Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33218 10.7051C5.44689 11.1192 6.86663 11.334 8.00004 11.334C9.13345 11.334 10.5532 11.1192 11.6679 10.7051C12.2267 10.4976 12.6676 10.2549 12.9575 9.99764C13.2422 9.74498 13.3334 9.52506 13.3334 9.33398H14.6667C14.6667 10.0266 14.3079 10.5818 13.8426 10.9948C13.3824 11.4032 12.7734 11.7168 12.1322 11.955C10.8469 12.4324 9.26663 12.6673 8.00004 12.6673C6.73345 12.6673 5.15319 12.4324 3.8679 11.955C3.22667 11.7168 2.61764 11.4032 2.1575 10.9948C1.69215 10.5818 1.33337 10.0266 1.33337 9.33398H2.66671C2.66671 9.52506 2.75793 9.74498 3.04259 9.99764C3.33245 10.2549 3.77341 10.4976 4.33218 10.7051Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00004 6.00065C2.36823 6.00065 2.66671 6.29913 2.66671 6.66732V9.33398C2.66671 9.70217 2.36823 10.0007 2.00004 10.0007C1.63185 10.0007 1.33337 9.70217 1.33337 9.33398V6.66732C1.33337 6.29913 1.63185 6.00065 2.00004 6.00065Z"
        fill="#003FBF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6.00065C14.3682 6.00065 14.6667 6.29913 14.6667 6.66732V9.33398C14.6667 9.70217 14.3682 10.0007 14 10.0007C13.6319 10.0007 13.3334 9.70217 13.3334 9.33398V6.66732C13.3334 6.29913 13.6319 6.00065 14 6.00065Z"
        fill="currentColor"
      />
    </svg>
  ),
  commerce: (props: IconProps) => (
    <svg viewBox="0 0 16 16" fill="none" {...getIconSizeProps(16, 16, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24659 10.6673C4.58821 10.6763 4.02183 10.2034 3.91325 9.55399L2.76659 2.66733H1.33325V1.33399H2.76659C3.41993 1.33157 3.97879 1.80293 4.08659 2.44733L4.23325 3.33399H12.5933C12.9738 3.33168 13.3372 3.49211 13.592 3.77486C13.8467 4.05761 13.9685 4.43573 13.9266 4.81399L13.4066 9.48066C13.3315 10.1592 12.756 10.6715 12.0733 10.6673H5.24659ZM5.23325 9.33399H12.0666L12.2933 7.33399H4.89992L5.23325 9.33399ZM12.5866 4.66733L12.4399 6.00066H4.66659L4.45325 4.66733H12.5866Z"
        fill="currentColor"
      />
      <path
        d="M4.66659 14.6673C5.40297 14.6673 5.99992 14.0704 5.99992 13.334C5.99992 12.5976 5.40297 12.0007 4.66659 12.0007C3.93021 12.0007 3.33325 12.5976 3.33325 13.334C3.33325 14.0704 3.93021 14.6673 4.66659 14.6673Z"
        fill="currentColor"
      />
      <path
        d="M11.9999 14.6673C12.7363 14.6673 13.3333 14.0704 13.3333 13.334C13.3333 12.5976 12.7363 12.0007 11.9999 12.0007C11.2635 12.0007 10.6666 12.5976 10.6666 13.334C10.6666 14.0704 11.2635 14.6673 11.9999 14.6673Z"
        fill="currentColor"
      />
    </svg>
  ),
  compartment: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...getIconSizeProps(24, 24, props)}
    >
      <path
        d="M22 3C22 2.44772 21.5523 2 21 2C20.4477 2 20 2.44772 20 3V14C20 14.5523 20.4477 15 21 15C21.5523 15 22 14.5523 22 14V3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51206 16.495C9.60917 16.6504 9.70109 16.8198 9.77842 17H20.25C21.2165 17 22 17.7835 22 18.75V20.25C22 21.2165 21.2165 22 20.25 22H3.75C2.7835 22 2 21.2165 2 20.25V15.75C2 14.7835 2.7835 14 3.75 14H5.5C6.59463 14 7.69665 14.6216 8.47003 15.2966C8.87425 15.6493 9.24016 16.06 9.51206 16.495ZM4 17V16H5.5C5.90537 16 6.55335 16.2784 7.15497 16.8034C7.22899 16.868 7.29922 16.9338 7.36527 17H4ZM4 20V19H20V20H4Z"
        fill="currentColor"
      />
      <path
        d="M3 2C3.55228 2 4 2.44772 4 3L4 11C4 11.5523 3.55229 12 3 12C2.44772 12 2 11.5523 2 11V3C2 2.44772 2.44772 2 3 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C6.89543 2 6 2.89543 6 4V10C6 11.1046 6.89543 12 8 12H16C17.1046 12 18 11.1046 18 10V4C18 2.89543 17.1046 2 16 2H8ZM8 4H16V10H8V4Z"
        fill="currentColor"
      />
    </svg>
  ),
  destination: (props: IconProps) => (
    <svg viewBox="0 0 12 12" fill="none" {...getIconSizeProps(12, 12, props)}>
      <path
        d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  ),
  dot: (props: IconProps) => (
    <svg viewBox="0 0 4 4" fill="none" {...getIconSizeProps(4, 4, props)}>
      <circle cx="2" cy="2" r="2" fill="currentColor" />
    </svg>
  ),
  download: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 16C3.55228 16 4 16.4477 4 17V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V17C20 16.4477 20.4477 16 21 16C21.5523 16 22 16.4477 22 17V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V17C2 16.4477 2.44772 16 3 16Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16C11.4477 16 11 15.5523 11 15L11 3C11 2.44771 11.4477 2 12 2C12.5523 2 13 2.44771 13 3L13 15C13 15.5523 12.5523 16 12 16Z"
        fill="currentColor"
      />
    </svg>
  ),
  errorCircle: (props: IconProps) => (
    <svg viewBox="0 0 12 12" fill="none" {...getIconSizeProps(12, 12, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17157 8.82843C4.73367 10.3905 7.26633 10.3905 8.82843 8.82843C10.3905 7.26633 10.3905 4.73367 8.82843 3.17157C7.26633 1.60948 4.73367 1.60948 3.17157 3.17157C1.60948 4.73367 1.60948 7.26633 3.17157 8.82843ZM2.46447 9.53553C4.41709 11.4882 7.58291 11.4882 9.53553 9.53553C11.4882 7.58291 11.4882 4.41709 9.53553 2.46447C7.58291 0.511845 4.41709 0.511845 2.46447 2.46447C0.511845 4.41709 0.511844 7.58291 2.46447 9.53553Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17157 8.82843C4.73367 10.3905 7.26633 10.3905 8.82843 8.82843C10.3905 7.26633 10.3905 4.73367 8.82843 3.17157C7.26633 1.60948 4.73367 1.60948 3.17157 3.17157C1.60948 4.73367 1.60948 7.26633 3.17157 8.82843ZM2.46447 9.53553C4.41709 11.4882 7.58291 11.4882 9.53553 9.53553C11.4882 7.58291 11.4882 4.41709 9.53553 2.46447C7.58291 0.511845 4.41709 0.511845 2.46447 2.46447C0.511845 4.41709 0.511844 7.58291 2.46447 9.53553Z"
        fill="currentColor"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.61741 2.66086C9.80471 2.86377 9.79206 3.1801 9.58915 3.3674L3.08915 9.3674C2.88623 9.5547 2.56991 9.54205 2.3826 9.33914C2.1953 9.13622 2.20796 8.8199 2.41087 8.63259L8.91087 2.63259C9.11378 2.44529 9.43011 2.45795 9.61741 2.66086Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.61741 2.66086C9.80471 2.86377 9.79206 3.1801 9.58915 3.3674L3.08915 9.3674C2.88623 9.5547 2.56991 9.54205 2.3826 9.33914C2.1953 9.13622 2.20796 8.8199 2.41087 8.63259L8.91087 2.63259C9.11378 2.44529 9.43011 2.45795 9.61741 2.66086Z"
        fill="currentColor"
        fillOpacity="0.15"
      />
    </svg>
  ),
  file: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H13C13.2652 1 13.5196 1.10536 13.7071 1.29289L20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V4C3 3.20435 3.31607 2.44129 3.87868 1.87868ZM6 3C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V9.41421L12.5858 3H6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1C13.5523 1 14 1.44772 14 2V8H20C20.5523 8 21 8.44772 21 9C21 9.55228 20.5523 10 20 10H13C12.4477 10 12 9.55228 12 9V2C12 1.44772 12.4477 1 13 1Z"
        fill="currentColor"
      />
    </svg>
  ),
  globe: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM9.71 19.667C8.72341 17.5743 8.15187 15.3102 8.027 13H4.062C4.25659 14.5389 4.89392 15.9882 5.89657 17.1717C6.89922 18.3552 8.22401 19.2221 9.71 19.667ZM10.03 13C10.181 15.439 10.878 17.73 12 19.752C13.1523 17.6766 13.8254 15.3695 13.97 13H10.03ZM19.938 13H15.973C15.8481 15.3102 15.2766 17.5743 14.29 19.667C15.776 19.2221 17.1008 18.3552 18.1034 17.1717C19.1061 15.9882 19.7434 14.5389 19.938 13ZM4.062 11H8.027C8.15187 8.68979 8.72341 6.42569 9.71 4.333C8.22401 4.77788 6.89922 5.64475 5.89657 6.8283C4.89392 8.01184 4.25659 9.4611 4.062 11ZM10.031 11H13.969C13.8248 8.6306 13.152 6.32353 12 4.248C10.8477 6.32345 10.1746 8.63052 10.03 11H10.031ZM14.29 4.333C15.2766 6.42569 15.8481 8.68979 15.973 11H19.938C19.7434 9.4611 19.1061 8.01184 18.1034 6.8283C17.1008 5.64475 15.776 4.77788 14.29 4.333Z"
        fill="currentColor"
      />
    </svg>
  ),
  infoCircle: (props: IconProps) => (
    <svg viewBox="0 0 16 16" fill="none" {...getIconSizeProps(16, 16, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 2.66671C5.05416 2.66671 2.66634 5.05452 2.66634 8.00004C2.66634 10.9456 5.05415 13.3334 7.99967 13.3334C10.9452 13.3334 13.333 10.9456 13.333 8.00004C13.333 5.05452 10.9452 2.66671 7.99967 2.66671ZM1.33301 8.00004C1.33301 4.31814 4.31778 1.33337 7.99967 1.33337C11.6816 1.33337 14.6663 4.31814 14.6663 8.00004C14.6663 11.6819 11.6816 14.6667 7.99967 14.6667C4.31778 14.6667 1.33301 11.6819 1.33301 8.00004Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99968 11.3334C7.63149 11.3334 7.33301 11.0349 7.33301 10.6667V8.00004C7.33301 7.63185 7.63149 7.33337 7.99968 7.33337C8.36787 7.33337 8.66634 7.63185 8.66634 8.00004V10.6667C8.66634 11.0349 8.36787 11.3334 7.99968 11.3334Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66634 5.33337C8.66634 5.70156 8.36787 6.00004 7.99968 6.00004H7.99357C7.62538 6.00004 7.3269 5.70156 7.3269 5.33337C7.3269 4.96518 7.62538 4.6667 7.99357 4.6667H7.99968C8.36787 4.6667 8.66634 4.96518 8.66634 5.33337Z"
        fill="currentColor"
      />
    </svg>
  ),
  line: (props: IconProps) => (
    <svg viewBox="0 0 100 2" fill="none" {...getIconSizeProps(100, 2, props)}>
      <path d="M0 1L100 1" stroke="currentColor" strokeWidth="1" />
    </svg>
  ),
  loader: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...getIconSizeProps(24, 24, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C12.5523 2 13 2.44772 13 3V7C13 7.55229 12.5523 8 12 8C11.4477 8 11 7.55229 11 7V3C11 2.44772 11.4477 2 12 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16C12.5523 16 13 16.4477 13 17L13 21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21L11 17C11 16.4477 11.4477 16 12 16Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L8.70711 7.29289C9.09763 7.68342 9.09763 8.31658 8.70711 8.70711C8.31658 9.09763 7.68342 9.09763 7.29289 8.70711L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2929 15.2929C15.6834 14.9024 16.3166 14.9024 16.7071 15.2929L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 11.4477 2.44772 11 3 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13H3C2.44772 13 2 12.5523 2 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 12C16 11.4477 16.4477 11 17 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L17 13C16.4477 13 16 12.5523 16 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70711 15.2929C9.09763 15.6834 9.09763 16.3166 8.70711 16.7071L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L7.29289 15.2929C7.68342 14.9024 8.31658 14.9024 8.70711 15.2929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L16.7071 8.70711C16.3166 9.09763 15.6834 9.09763 15.2929 8.70711C14.9024 8.31658 14.9024 7.68342 15.2929 7.29289L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
  location: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6ZM10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4361 22.8259C11.4364 22.8261 11.4367 22.8262 12 22L11.4361 22.8259C11.776 23.0576 12.2235 23.0579 12.5633 22.8262L12 22C12.5633 22.8262 12.5631 22.8264 12.5633 22.8262L12.5646 22.8254L12.5666 22.824L12.5727 22.8198L12.5935 22.8055C12.611 22.7932 12.636 22.7758 12.6679 22.7532C12.7316 22.7081 12.8229 22.6425 12.9379 22.5576C13.1678 22.3878 13.4929 22.1402 13.8815 21.8237C14.6572 21.192 15.6938 20.2794 16.7336 19.1568C18.7751 16.953 21 13.7572 21 10.1818C21 7.75381 20.0571 5.42084 18.3719 3.69728C16.6859 1.97296 14.3943 1 12 1C9.60571 1 7.31415 1.97296 5.62814 3.69728C3.94288 5.42084 3 7.75381 3 10.1818C3 13.7572 5.22493 16.953 7.26638 19.1568C8.30623 20.2794 9.34284 21.192 10.1185 21.8237C10.5071 22.1402 10.8322 22.3878 11.0621 22.5576C11.1771 22.6425 11.2684 22.7081 11.3321 22.7532C11.364 22.7758 11.389 22.7932 11.4065 22.8055L11.4273 22.8198L11.4334 22.824L11.4361 22.8259ZM7.05815 5.09552C8.37273 3.75107 10.1508 3 12 3C13.8492 3 15.6273 3.75107 16.9418 5.09552C18.2572 6.44073 19 8.26992 19 10.1818C19 12.9701 17.2249 15.6834 15.2664 17.7977C14.3062 18.8342 13.3428 19.683 12.6185 20.2729C12.3835 20.4643 12.1745 20.6277 12 20.7608C11.8255 20.6277 11.6165 20.4643 11.3815 20.2729C10.6572 19.683 9.69377 18.8342 8.73362 17.7977C6.77507 15.6834 5 12.9701 5 10.1818C5 8.26992 5.74283 6.44073 7.05815 5.09552Z"
        fill="currentColor"
      />
    </svg>
  ),
  lock: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7V11H7V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11H15V7C15 6.20435 14.6839 5.44129 14.1213 4.87868C13.5587 4.31607 12.7956 4 12 4C11.2044 4 10.4413 4.31607 9.87868 4.87868C9.31607 5.44129 9 6.20435 9 7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13C5.44772 13 5 13.4477 5 14V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V14C19 13.4477 18.5523 13 18 13H6ZM3 14C3 12.3431 4.34315 11 6 11H18C19.6569 11 21 12.3431 21 14V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V14Z"
        fill="currentColor"
      />
    </svg>
  ),
  minus: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
        fill="currentColor"
      />
    </svg>
  ),
  moon: (props: IconProps) => (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...getIconSizeProps(24, 24, props)}
    >
      <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z" />
    </svg>
  ),
  moreHorizontal: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14Z"
        fill="currentColor"
      />
    </svg>
  ),
  oneWay: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...getIconSizeProps(24, 24, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4349 16.9142C14.0444 16.5237 14.0444 15.8905 14.4349 15.5L17.9853 11.9497L14.4497 8.41414C14.0592 8.02361 14.0592 7.39045 14.4497 6.99992C14.8403 6.6094 15.4734 6.6094 15.864 6.99992L20.1066 11.2426C20.4971 11.6331 20.4971 12.2663 20.1066 12.6568L15.8492 16.9142C15.4586 17.3047 14.8255 17.3047 14.4349 16.9142Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11.9496C3 11.3974 3.44771 10.9496 4 10.9496L19.3995 10.9496C19.9518 10.9496 20.3995 11.3974 20.3995 11.9496C20.3995 12.5019 19.9518 12.9496 19.3995 12.9496L4 12.9496C3.44772 12.9496 3 12.5019 3 11.9496Z"
        fill="currentColor"
      />
    </svg>
  ),
  oneWayAlt: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      {...getIconSizeProps(16, 16, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6233 11.2761C9.36295 11.0158 9.36295 10.5936 9.6233 10.3333L11.9902 7.96641L9.63316 5.60938C9.37281 5.34904 9.37281 4.92693 9.63316 4.66658C9.89351 4.40623 10.3156 4.40623 10.576 4.66658L13.4044 7.495C13.6647 7.75535 13.6647 8.17746 13.4044 8.43781L10.5661 11.2761C10.3058 11.5365 9.88365 11.5365 9.6233 11.2761Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7.96639C2 7.5982 2.29848 7.29972 2.66667 7.29972L12.933 7.29972C13.3012 7.29972 13.5997 7.5982 13.5997 7.96638C13.5997 8.33457 13.3012 8.63305 12.933 8.63305L2.66667 8.63305C2.29848 8.63305 2 8.33458 2 7.96639Z"
        fill="currentColor"
      />
    </svg>
  ),
  pet: (props: IconProps) => (
    <svg viewBox="0 0 16 16" fill="none" {...getIconSizeProps(16, 16, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3333 8.66663C12.3939 8.66663 13.3333 7.72855 13.3333 6.46966L13.3333 5.09387C12.6291 5.40975 11.9703 5.58673 11.2932 5.5865C10.6299 5.58628 9.99668 5.41611 9.33325 5.11268L9.33325 6.46966C9.33325 7.72855 10.2726 8.66663 11.3333 8.66663ZM14.6666 6.46966C14.6666 8.37385 13.2182 9.99996 11.3333 9.99996C9.44836 9.99996 7.99992 8.37385 7.99992 6.46966L7.99992 2.82083L9.01028 3.42871C9.99018 4.01827 10.6623 4.25296 11.2937 4.25317C11.9295 4.25339 12.6305 4.01616 13.6685 3.42149L14.6666 2.84972L14.6666 6.46966Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33325 10.3333C1.33325 8.12415 3.12411 6.33329 5.33325 6.33329L8.33325 6.33329V7.66663H5.33325C3.86049 7.66663 2.66659 8.86053 2.66659 10.3333L2.66659 12.6666L13.3333 12.6666V14L2.66659 14C1.93021 14 1.33325 13.403 1.33325 12.6666L1.33325 10.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3333 12V9.33329H12.6666V12C12.6666 12.3681 12.9651 12.6666 13.3333 12.6666H13.9999C14.3681 12.6666 14.6666 12.9651 14.6666 13.3333C14.6666 13.7015 14.3681 14 13.9999 14H13.3333C12.2287 14 11.3333 13.1045 11.3333 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33325 4.66663C1.33325 3.56206 2.22868 2.66663 3.33325 2.66663H3.99992C4.36811 2.66663 4.66659 2.9651 4.66659 3.33329C4.66659 3.70148 4.36811 3.99996 3.99992 3.99996L3.33325 3.99996C2.96506 3.99996 2.66659 4.29844 2.66659 4.66663L2.66659 10.3333H1.33325L1.33325 4.66663Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99992 10.6666C5.36811 10.6666 5.66659 10.9651 5.66659 11.3333V13.3331L4.33325 13.3333V11.3333C4.33325 10.9651 4.63173 10.6666 4.99992 10.6666Z"
        fill="currentColor"
      />
    </svg>
  ),
  plus: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
        fill="currentColor"
      />
    </svg>
  ),
  powerSocket: (props: IconProps) => (
    <svg viewBox="0 0 14 14" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00001 1.66634C4.05449 1.66634 1.66668 4.05416 1.66668 6.99967C1.66668 7.36786 1.3682 7.66634 1.00001 7.66634C0.63182 7.66634 0.333344 7.36786 0.333344 6.99967C0.333344 3.31778 3.31811 0.333008 7.00001 0.333008C10.6819 0.333008 13.6667 3.31778 13.6667 6.99967C13.6667 10.6816 10.6819 13.6663 7.00001 13.6663C5.98726 13.6663 5.02552 13.4401 4.16417 13.0347C3.43585 12.6919 3.05708 12.0337 2.99848 11.3458C2.94131 10.6746 3.18355 9.9743 3.66606 9.44308L4.17319 8.88477L5.16016 9.78125L4.65303 10.3396C4.40676 10.6107 4.3029 10.9497 4.327 11.2326C4.34967 11.4988 4.47954 11.7095 4.73194 11.8283C5.4194 12.1518 6.18762 12.333 7.00001 12.333C9.94553 12.333 12.3333 9.94519 12.3333 6.99967C12.3333 4.05416 9.94553 1.66634 7.00001 1.66634Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21895 3.8616C4.4793 3.60125 4.90141 3.60125 5.16176 3.8616L9.87581 8.57565C10.1362 8.836 10.1362 9.25811 9.87581 9.51846C8.31371 11.0806 5.78105 11.0806 4.21895 9.51846C2.65686 7.95636 2.65686 5.4237 4.21895 3.8616ZM4.74976 5.33522C4.14581 6.35783 4.28314 7.69703 5.16176 8.57565C6.04039 9.45427 7.37959 9.5916 8.40219 8.98765L4.74976 5.33522Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80475 3.19494C9.0651 3.45529 9.0651 3.8774 8.80475 4.13775L6.80475 6.13775L5.86194 5.19494L7.86194 3.19494C8.12229 2.93459 8.5444 2.93459 8.80475 3.19494Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8047 5.19494C11.0651 5.45529 11.0651 5.8774 10.8047 6.13775L8.80475 8.13775L7.86194 7.19494L9.86194 5.19494C10.1223 4.93459 10.5444 4.93459 10.8047 5.19494Z"
        fill="currentColor"
      />
    </svg>
  ),
  removeCircle: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM13 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H13H11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11H13Z"
        fill="currentColor"
      />
    </svg>
  ),
  replace: (props: IconProps) => (
    <svg viewBox="0 0 21 22" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.92436 11.025C7.31488 11.4155 7.31488 12.0487 6.92436 12.4392L3.38883 15.9747L6.92436 19.5103C7.31488 19.9008 7.31489 20.534 6.92436 20.9245C6.53384 21.315 5.90067 21.315 5.51015 20.9245L1.26751 16.6819C0.876981 16.2913 0.876981 15.6582 1.26751 15.2676L5.51014 11.025C5.90067 10.6345 6.53383 10.6345 6.92436 11.025Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8741 11.9245C20.4264 11.9245 20.8741 12.3722 20.8741 12.9245V12.9747C20.8741 15.1839 19.0832 16.9748 16.8741 16.9748L1.97461 16.9748C1.42232 16.9748 0.974609 16.527 0.974609 15.9748C0.974609 15.4225 1.42233 14.9748 1.97461 14.9748L16.8741 14.9748C17.9787 14.9748 18.8741 14.0793 18.8741 12.9747V12.9245C18.8741 12.3722 19.3218 11.9245 19.8741 11.9245Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9495 10.8994C14.559 10.5089 14.559 9.87573 14.9495 9.48521L18.485 5.94967L14.9495 2.41414C14.559 2.02361 14.559 1.39045 14.9495 0.999925C15.34 0.6094 15.9732 0.6094 16.3637 0.999924L20.6063 5.24256C20.9969 5.63309 20.9969 6.26625 20.6063 6.65678L16.3637 10.8994C15.9732 11.2899 15.34 11.2899 14.9495 10.8994Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99973 9.99991C1.44745 9.99991 0.99973 9.55219 0.99973 8.99991L0.99973 8.94964C0.99973 6.7405 2.79059 4.94964 4.99973 4.94964L19.8992 4.94964C20.4515 4.94964 20.8992 5.39735 20.8992 5.94964C20.8992 6.50192 20.4515 6.94964 19.8992 6.94964L4.99973 6.94964C3.89516 6.94964 2.99973 7.84507 2.99973 8.94964L2.99973 8.99991C2.99973 9.55219 2.55202 9.99991 1.99973 9.99991Z"
        fill="currentColor"
      />
    </svg>
  ),
  return: (props: IconProps) => (
    <svg viewBox="0 0 16 16" fill="none" {...getIconSizeProps(16, 16, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29982 8.50003C5.56017 8.76038 5.56017 9.18249 5.29982 9.44283L2.94279 11.7999L5.29982 14.1569C5.56017 14.4172 5.56017 14.8393 5.29982 15.0997C5.03947 15.36 4.61736 15.36 4.35701 15.0997L1.52858 12.2713C1.26823 12.0109 1.26823 11.5888 1.52858 11.3285L4.35701 8.50003C4.61736 8.23968 5.03947 8.23968 5.29982 8.50003Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5996 11.7999C14.5996 12.1681 14.3012 12.4665 13.933 12.4665L1.99998 12.4665C1.63179 12.4665 1.33332 12.1681 1.33332 11.7999C1.33332 11.4317 1.63179 11.1332 1.99998 11.1332L13.933 11.1332C14.3012 11.1332 14.5996 11.4317 14.5996 11.7999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6331 8.43299C10.3728 8.17264 10.3728 7.75053 10.6331 7.49018L12.9902 5.13315L10.6331 2.77613C10.3728 2.51578 10.3728 2.09367 10.6331 1.83332C10.8935 1.57297 11.3156 1.57297 11.576 1.83332L14.4044 4.66175C14.6647 4.9221 14.6647 5.34421 14.4044 5.60456L11.576 8.43299C11.3156 8.69334 10.8935 8.69334 10.6331 8.43299Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33331 5.13315C1.33331 4.76496 1.63179 4.46648 1.99998 4.46648L13.933 4.46648C14.3012 4.46648 14.5996 4.76495 14.5996 5.13314C14.5996 5.50133 14.3012 5.79981 13.933 5.79981L1.99998 5.79981C1.63179 5.79981 1.33331 5.50134 1.33331 5.13315Z"
        fill="currentColor"
      />
    </svg>
  ),
  search: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 17C14.0899 17 17 14.0899 17 10.5C17 6.91015 14.0899 4 10.5 4C6.91015 4 4 6.91015 4 10.5C4 14.0899 6.91015 17 10.5 17ZM19 10.5C19 15.1944 15.1944 19 10.5 19C5.80558 19 2 15.1944 2 10.5C2 5.80558 5.80558 2 10.5 2C15.1944 2 19 5.80558 19 10.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9429 15.9429C16.3334 15.5524 16.9666 15.5524 17.3571 15.9429L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L15.9429 17.3571C15.5524 16.9666 15.5524 16.3334 15.9429 15.9429Z"
        fill="currentColor"
      />
    </svg>
  ),
  seat: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
      {...getIconSizeProps(24, 24, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1C1.77614 1 2 1.22386 2 1.5V3.48629C2 3.73904 2.06387 3.9877 2.18568 4.20916L3.77546 7.09969C5.27768 6.42555 6.38883 6.20334 7.97418 6.38141C8.73004 6.46632 9.25 7.12068 9.25 7.84004V9.5H3.95436L1.30946 4.69108C1.10645 4.32197 1 3.90754 1 3.48629V1.5C1 1.22386 1.22386 1 1.5 1ZM4.25903 7.9789L4.54564 8.5H8.25V7.84004C8.25 7.58798 8.07204 7.3987 7.86256 7.37517C6.51892 7.22424 5.59524 7.38767 4.25903 7.9789Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10.5V9H5V10.5C5 10.7761 4.77614 11 4.5 11C4.22386 11 4 10.7761 4 10.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 10.5V9H8.5V10.5C8.5 10.7761 8.27614 11 8 11C7.72386 11 7.5 10.7761 7.5 10.5Z"
        fill="currentColor"
      />
    </svg>
  ),
  shoppingCart: (props: IconProps) => (
    <svg viewBox="0 0 20 20" fill="none" {...getIconSizeProps(20, 20, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55817 13.3333C5.7352 13.3446 5.02723 12.7534 4.8915 11.9416L3.45817 3.3333H1.6665L1.6665 1.66664L3.45817 1.66664C4.27485 1.66361 4.97342 2.25281 5.10817 3.0583L5.2915 4.16664L15.7415 4.16664C16.2172 4.16375 16.6715 4.36428 16.9899 4.71772C17.3083 5.07115 17.4605 5.54381 17.4082 6.01664L16.7582 11.85C16.6643 12.6982 15.9449 13.3385 15.0915 13.3333L6.55817 13.3333ZM6.5415 11.6666L15.0832 11.6666L15.3665 9.16664L6.12484 9.16664L6.5415 11.6666ZM15.7332 5.8333L15.5498 7.49997L5.83317 7.49997L5.5665 5.8333L15.7332 5.8333Z"
        fill="currentColor"
      />
      <path
        d="M5.83317 18.3333C6.75364 18.3333 7.49984 17.5871 7.49984 16.6667C7.49984 15.7462 6.75364 15 5.83317 15C4.9127 15 4.1665 15.7462 4.1665 16.6667C4.1665 17.5871 4.9127 18.3333 5.83317 18.3333Z"
        fill="currentColor"
      />
      <path
        d="M14.9998 18.3333C15.9203 18.3333 16.6665 17.5871 16.6665 16.6667C16.6665 15.7462 15.9203 15 14.9998 15C14.0794 15 13.3332 15.7462 13.3332 16.6667C13.3332 17.5871 14.0794 18.3333 14.9998 18.3333Z"
        fill="currentColor"
      />
    </svg>
  ),
  sun: (props: IconProps) => (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...getIconSizeProps(24, 24, props)}
    >
      <circle cx="12" cy="12" r="4" />
      <path d="M12 2v2" />
      <path d="M12 20v2" />
      <path d="m4.93 4.93 1.41 1.41" />
      <path d="m17.66 17.66 1.41 1.41" />
      <path d="M2 12h2" />
      <path d="M20 12h2" />
      <path d="m6.34 17.66-1.41 1.41" />
      <path d="m19.07 4.93-1.41 1.41" />
    </svg>
  ),
  ticket: (props: IconProps) => (
    <svg viewBox="0 0 16 16" fill="none" {...getIconSizeProps(16, 16, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3335 4.00004C1.3335 3.63185 1.63197 3.33337 2.00016 3.33337L14.0002 3.33337C14.3684 3.33337 14.6668 3.63185 14.6668 4.00004V7.27618L13.943 8.00004L14.6668 8.7239L14.6668 12C14.6668 12.3682 14.3684 12.6667 14.0002 12.6667L2.00016 12.6667C1.63197 12.6667 1.3335 12.3682 1.3335 12L1.3335 8.7239L2.05735 8.00004L1.3335 7.27618L1.3335 4.00004ZM2.66683 4.66671L2.66683 6.7239L3.94297 8.00004L2.66683 9.27618L2.66683 11.3334L13.3335 11.3334V9.27618L12.0574 8.00004L13.3335 6.7239V4.66671L2.66683 4.66671Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6668 4.00004V6.00004H9.3335V4.00004H10.6668Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6668 10V12H9.3335L9.3335 10L10.6668 10Z"
        fill="currentColor"
      />
      <path
        d="M9.3335 7.33337L10.6668 7.33337V8.66671L9.3335 8.66671V7.33337Z"
        fill="currentColor"
      />
    </svg>
  ),
  ticketReturn: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6C6 5.44772 6.44772 5 7 5H21C21.5523 5 22 5.44772 22 6V10.9142L20.9142 12L22 13.0858V18C22 18.5523 21.5523 19 21 19H7C6.44772 19 6 18.5523 6 18C6 17.4477 6.44772 17 7 17H20V13.9142L18.0858 12L20 10.0858V7H7C6.44772 7 6 6.55228 6 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6V9H14V6H16Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 15V18H14V15H16Z"
        fill="currentColor"
      />
      <path d="M14 11H16V13H14V11Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12C8 12.5523 7.55229 13 7 13L3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11L7 11C7.55229 11 8 11.4477 8 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.14018 15.7682C5.71591 16.1218 5.08534 16.0645 4.73178 15.6402L2.23178 12.6402C1.92274 12.2693 1.92274 11.7307 2.23178 11.3598L4.73178 8.35982C5.08534 7.93554 5.71591 7.87821 6.14018 8.23178C6.56446 8.58534 6.62179 9.21591 6.26822 9.64018L4.30171 12L6.26822 14.3598C6.62179 14.7841 6.56446 15.4147 6.14018 15.7682Z"
        fill="currentColor"
      />
    </svg>
  ),
  time: (props: IconProps) => (
    <svg viewBox="0 0 14 14" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99999 12.3334C9.94551 12.3334 12.3333 9.94556 12.3333 7.00004C12.3333 4.05452 9.94551 1.66671 6.99999 1.66671C4.05447 1.66671 1.66666 4.05452 1.66666 7.00004C1.66666 9.94556 4.05447 12.3334 6.99999 12.3334ZM13.6667 7.00004C13.6667 10.6819 10.6819 13.6667 6.99999 13.6667C3.31809 13.6667 0.333326 10.6819 0.333326 7.00004C0.333326 3.31814 3.31809 0.333374 6.99999 0.333374C10.6819 0.333374 13.6667 3.31814 13.6667 7.00004Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.66671C7.36819 2.66671 7.66667 2.96519 7.66667 3.33338V7.00004C7.66667 7.36823 7.36819 7.66671 7 7.66671C6.63181 7.66671 6.33333 7.36823 6.33333 7.00004V3.33338C6.33333 2.96519 6.63181 2.66671 7 2.66671Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9714 9.97145C9.71105 10.2318 9.28894 10.2318 9.02859 9.97145L6.5286 7.47145C6.26825 7.2111 6.26825 6.78899 6.5286 6.52864C6.78895 6.26829 7.21106 6.26829 7.47141 6.52864L9.9714 9.02864C10.2318 9.28899 10.2318 9.7111 9.9714 9.97145Z"
        fill="currentColor"
      />
    </svg>
  ),
  train: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        d="M17.2 20L19 21.5V22H5V21.5L6.8 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V7C3 5.93913 3.42143 4.92172 4.17157 4.17157C4.92172 3.42143 5.93913 3 7 3H17C18.0609 3 19.0783 3.42143 19.8284 4.17157C20.5786 4.92172 21 5.93913 21 7V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20H17.2ZM7 5C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V18H19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H7ZM12 17C11.4696 17 10.9609 16.7893 10.5858 16.4142C10.2107 16.0391 10 15.5304 10 15C10 14.4696 10.2107 13.9609 10.5858 13.5858C10.9609 13.2107 11.4696 13 12 13C12.5304 13 13.0391 13.2107 13.4142 13.5858C13.7893 13.9609 14 14.4696 14 15C14 15.5304 13.7893 16.0391 13.4142 16.4142C13.0391 16.7893 12.5304 17 12 17ZM6 7H18V11H6V7Z"
        fill="currentColor"
      />
    </svg>
  ),
  transfer: (props: IconProps) => (
    <svg viewBox="0 0 16 16" fill="none" {...getIconSizeProps(16, 16, props)}>
      <path
        d="M9.33331 8.00006C9.33331 8.73644 8.73636 9.33339 7.99998 9.33339C7.2636 9.33339 6.66665 8.73644 6.66665 8.00006C6.66665 7.26368 7.2636 6.66672 7.99998 6.66672C8.73636 6.66672 9.33331 7.26368 9.33331 8.00006Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33331 8.00006C1.33331 7.63187 1.63179 7.33339 1.99998 7.33339H4.33331C4.7015 7.33339 4.99998 7.63187 4.99998 8.00006C4.99998 8.36825 4.7015 8.66672 4.33331 8.66672H1.99998C1.63179 8.66672 1.33331 8.36825 1.33331 8.00006Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23986 5.48791C2.52271 5.2522 2.94308 5.29042 3.17879 5.57327L4.84546 7.57327C5.05149 7.8205 5.05149 8.17962 4.84546 8.42685L3.17879 10.4268C2.94308 10.7097 2.52271 10.7479 2.23986 10.5122C1.95701 10.2765 1.91879 9.85612 2.1545 9.57327L3.46551 8.00006L2.1545 6.42685C1.91879 6.144 1.95701 5.72362 2.23986 5.48791Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 8.00006C11 7.63187 11.2985 7.33339 11.6666 7.33339H14C14.3682 7.33339 14.6666 7.63187 14.6666 8.00006C14.6666 8.36825 14.3682 8.66672 14 8.66672H11.6666C11.2985 8.66672 11 8.36825 11 8.00006Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9065 5.48791C12.1894 5.2522 12.6098 5.29042 12.8455 5.57327L14.5121 7.57327C14.7182 7.8205 14.7182 8.17962 14.5121 8.42685L12.8455 10.4268C12.6098 10.7097 12.1894 10.7479 11.9065 10.5122C11.6237 10.2765 11.5855 9.85612 11.8212 9.57327L13.1322 8.00006L11.8212 6.42685C11.5855 6.144 11.6237 5.72362 11.9065 5.48791Z"
        fill="currentColor"
      />
    </svg>
  ),
  transportFrom: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        d="M7.05815 5.09552C8.37273 3.75107 10.1508 3 12 3C12.9054 3 13.7931 3.17997 14.6177 3.52111C15.128 3.73225 15.7129 3.4897 15.924 2.97936C16.1352 2.46903 15.8926 1.88416 15.3823 1.67303C14.3184 1.2329 13.1712 1 12 1C9.60571 1 7.31415 1.97296 5.62814 3.69728C3.94288 5.42084 3 7.75381 3 10.1818C3 13.7572 5.22493 16.953 7.26638 19.1568C8.30623 20.2794 9.34284 21.192 10.1185 21.8237C10.5071 22.1402 10.8322 22.3878 11.0621 22.5576C11.1771 22.6425 11.2684 22.7081 11.3321 22.7532C11.364 22.7758 11.389 22.7932 11.4065 22.8055L11.4273 22.8198L11.4334 22.824L11.4354 22.8254C11.4357 22.8256 11.4367 22.8262 12 22L11.4367 22.8262C11.7765 23.0579 12.2235 23.0579 12.5633 22.8262L12 22C12.5633 22.8262 12.5632 22.8263 12.5633 22.8262L12.5647 22.8253L12.5669 22.8238L12.5738 22.8191L12.5974 22.8027C12.6173 22.7888 12.6458 22.7689 12.682 22.7432C12.7545 22.6917 12.8584 22.6167 12.9889 22.5198C13.2497 22.3259 13.6174 22.0433 14.0528 21.683C14.9217 20.9641 16.0711 19.927 17.1822 18.6591C17.5462 18.2437 17.5046 17.6119 17.0892 17.2479C16.6739 16.8839 16.0421 16.9256 15.6781 17.3409C14.6567 18.5064 13.5909 19.4694 12.7779 20.1421C12.4775 20.3906 12.2131 20.5983 12 20.7608C11.8255 20.6277 11.6165 20.4643 11.3815 20.2729C10.6572 19.683 9.69377 18.8342 8.73362 17.7977C6.77507 15.6834 5 12.9701 5 10.1818C5 8.26992 5.74283 6.44073 7.05815 5.09552Z"
        fill="currentColor"
      />
      <path
        d="M18.2929 13.7071C17.9024 13.3166 17.9024 12.6834 18.2929 12.2929L19.5858 11L13 11C12.4477 11 12 10.5523 12 10C12 9.44771 12.4477 9 13 9L19.5858 9L18.2929 7.70711C17.9024 7.31658 17.9024 6.68342 18.2929 6.29289C18.6834 5.90237 19.3166 5.90237 19.7071 6.29289L22.7071 9.29289C23.0976 9.68342 23.0976 10.3166 22.7071 10.7071L19.7071 13.7071C19.3166 14.0976 18.6834 14.0976 18.2929 13.7071Z"
        fill="currentColor"
      />
    </svg>
  ),
  transportReturn: (props: IconProps) => (
    <svg viewBox="0 0 20 20" fill="none" {...getIconSizeProps(20, 20, props)}>
      <path
        d="M5.88179 4.24618C6.97727 3.12581 8.45902 2.49992 10 2.49992C10.7545 2.49992 11.4943 2.64989 12.1814 2.93418C12.6067 3.11012 13.0941 2.908 13.27 2.48272C13.446 2.05744 13.2439 1.57005 12.8186 1.39411C11.932 1.02733 10.976 0.833252 10 0.833252C8.00476 0.833252 6.09512 1.64405 4.69012 3.08099C3.28573 4.51729 2.5 6.46143 2.5 8.48477C2.5 11.4643 4.35411 14.1274 6.05532 15.9639C6.92185 16.8994 7.7857 17.6599 8.4321 18.1863C8.75594 18.4501 9.02685 18.6564 9.21843 18.7979C9.31426 18.8687 9.39036 18.9233 9.44345 18.9609C9.47 18.9797 9.4908 18.9943 9.50545 19.0045L9.52277 19.0164L9.52787 19.0199L9.52952 19.0211C9.52975 19.0212 9.53055 19.0218 10 18.3333L9.53055 19.0218C9.81374 19.2149 10.1863 19.2149 10.4694 19.0218L10 18.3333C10.4694 19.0218 10.4693 19.0218 10.4694 19.0218L10.4706 19.021L10.4724 19.0197L10.4782 19.0158L10.4978 19.0022C10.5144 18.9906 10.5381 18.974 10.5683 18.9526C10.6288 18.9096 10.7154 18.8472 10.8241 18.7664C11.0414 18.6048 11.3478 18.3694 11.7107 18.0691C12.4348 17.47 13.3925 16.6058 14.3185 15.5492C14.6219 15.203 14.5872 14.6765 14.241 14.3732C13.8949 14.0699 13.3684 14.1046 13.0651 14.4507C12.2139 15.4219 11.3258 16.2244 10.6482 16.785C10.3979 16.9921 10.1776 17.1652 10 17.3006C9.85456 17.1897 9.68039 17.0535 9.48456 16.894C8.88097 16.4024 8.07815 15.6951 7.27802 14.8313C5.64589 13.0694 4.16667 10.8083 4.16667 8.48477C4.16667 6.89152 4.78569 5.36719 5.88179 4.24618Z"
        fill="currentColor"
      />
      <path
        d="M13.9226 5.244C14.248 5.56943 14.248 6.09707 13.9226 6.42251L12.8452 7.49992L18.3333 7.49992C18.7936 7.49992 19.1667 7.87301 19.1667 8.33325C19.1667 8.79349 18.7936 9.16658 18.3333 9.16658L12.8452 9.16658L13.9226 10.244C14.248 10.5694 14.248 11.0971 13.9226 11.4225C13.5972 11.7479 13.0695 11.7479 12.7441 11.4225L10.2441 8.92251C9.91864 8.59707 9.91864 8.06943 10.2441 7.744L12.7441 5.244C13.0695 4.91856 13.5972 4.91856 13.9226 5.244Z"
        fill="currentColor"
      />
    </svg>
  ),
  transportTo: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        d="M16.9418 5.09552C15.6273 3.75107 13.8492 3 12 3C11.0946 3 10.2069 3.17997 9.38229 3.52111C8.87196 3.73225 8.28709 3.4897 8.07596 2.97936C7.86482 2.46903 8.10737 1.88416 8.61771 1.67303C9.68155 1.2329 10.8288 1 12 1C14.3943 1 16.6859 1.97296 18.3719 3.69728C20.0571 5.42084 21 7.75381 21 10.1818C21 13.7572 18.7751 16.953 16.7336 19.1568C15.6938 20.2794 14.6572 21.192 13.8815 21.8237C13.4929 22.1402 13.1678 22.3878 12.9379 22.5576C12.8229 22.6425 12.7316 22.7081 12.6679 22.7532C12.636 22.7758 12.611 22.7932 12.5935 22.8055L12.5727 22.8198L12.5666 22.824L12.5646 22.8254C12.5646 22.8254 12.5633 22.8262 12 22L12.5633 22.8262C12.2235 23.0579 11.7765 23.0579 11.4367 22.8262L12 22C11.4367 22.8262 11.4367 22.8262 11.4367 22.8262L11.4353 22.8253L11.4331 22.8238L11.4262 22.8191L11.4026 22.8027C11.3827 22.7888 11.3542 22.7689 11.318 22.7432C11.2455 22.6917 11.1416 22.6167 11.0111 22.5198C10.7503 22.3259 10.3826 22.0433 9.94715 21.683C9.07828 20.9641 7.92895 19.927 6.81777 18.6591C6.45377 18.2437 6.4954 17.6119 6.91076 17.2479C7.32612 16.8839 7.95791 16.9256 8.32191 17.3409C9.34331 18.5064 10.4091 19.4694 11.2221 20.1421C11.5225 20.3906 11.7869 20.5983 12 20.7608C12.1745 20.6277 12.3835 20.4643 12.6185 20.2729C13.3428 19.683 14.3062 18.8342 15.2664 17.7977C17.2249 15.6834 19 12.9701 19 10.1818C19 8.26992 18.2572 6.44073 16.9418 5.09552Z"
        fill="currentColor"
      />
      <path
        d="M7.29289 6.29289C6.90237 6.68342 6.90237 7.31658 7.29289 7.70711L8.58578 9L2 9C1.44772 9 1 9.44772 1 10C1 10.5523 1.44772 11 2 11L8.58579 11L7.29289 12.2929C6.90237 12.6834 6.90237 13.3166 7.29289 13.7071C7.68342 14.0976 8.31658 14.0976 8.70711 13.7071L11.7071 10.7071C12.0976 10.3166 12.0976 9.68342 11.7071 9.29289L8.70711 6.29289C8.31658 5.90237 7.68342 5.90237 7.29289 6.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
  travelPass: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        d="M10.9846 8.99999H8.98804V11H10.9846V8.99999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0068 6.00003H20.1132C20.6136 6.00003 21.0935 6.19916 21.4474 6.5536C21.8012 6.90804 22 7.38877 22 7.89003V20.11C22 20.6113 21.8012 21.092 21.4474 21.4464C21.0935 21.8009 20.6136 22 20.1132 22H6.92579C5.90817 22 5.95572 21.2041 6.01515 20.2095C6.01927 20.1405 6.02345 20.0706 6.02733 20V18H2.93262C1.92901 18 1.96994 17.2259 2.02152 16.2504C2.02586 16.1683 2.03028 16.0847 2.03416 16V11L3.03245 10L2.03416 9.00003V4.00004C2.00675 3.47904 2.19441 2.96967 2.55314 2.59142C2.91186 2.21317 3.41008 1.99932 3.93091 2.00004H16.1001C17.1137 1.99329 17.9535 2.78607 18.0068 3.80004V6.00003ZM4.03074 12V16H16.0102V12L14.0137 10L16.0102 8.00003V4.00004H4.03074V8.00003L6.02733 10L4.03074 12ZM8.02391 20V18H16.0801C17.1014 18.0176 17.9532 17.2218 18.0068 16.2V11L17.0085 10L18.0068 9.00003V8.00003H20.0034V20H8.02391Z"
        fill="currentColor"
      />
    </svg>
  ),
  turnitLogo: (props: IconProps) => (
    <svg viewBox="0 0 87 24" fill="none" {...getIconSizeProps(87, 24, props)}>
      <g clipPath="url(#clip0_6805_17004)">
        <path
          d="M68.8218 4.00937C69.3536 4.00937 69.812 3.82201 70.1971 3.42857C70.5822 3.03514 70.7656 2.56674 70.7656 2.02342C70.7656 1.48009 70.5822 0.992975 70.1971 0.599532C69.812 0.206089 69.3536 0 68.8218 0C68.2534 0 67.7949 0.206089 67.4098 0.599532C67.0247 0.992975 66.8414 1.46136 66.8414 2.02342C66.8414 2.56674 67.0247 3.03514 67.4098 3.42857C67.7765 3.82201 68.2534 4.00937 68.8218 4.00937Z"
          fill="currentColor"
        />
        <path
          d="M13.4414 4.32787V7.83139H3.59415V13.452C3.59415 15.5691 4.51102 17.274 6.34477 18.5481L6.36311 18.5668C7.6284 19.4286 9.13207 19.8033 10.6541 19.8033H13.4597V23.4005H9.79221C7.09661 23.4005 4.78608 22.4637 2.87898 20.5152C0.953549 18.5668 0 16.2436 0 13.5456V0.468384H3.59415V4.32787H13.4414Z"
          fill="currentColor"
        />
        <path
          d="M64.6765 13.2084V23.4005H61.2841V13.2084C61.2841 11.541 60.6973 10.0983 59.5054 8.91803C58.3134 7.7377 56.9014 7.13816 55.2695 7.13816C53.6007 7.13816 52.1887 7.7377 51.0152 8.91803C49.8415 10.0983 49.2547 11.541 49.2547 13.2084V23.4005H45.8623V13.2084C45.8623 10.5855 46.7792 8.33723 48.6312 6.48242C50.4834 4.60889 52.7021 3.67212 55.2878 3.67212C57.8733 3.67212 60.0922 4.60889 61.9259 6.48242C63.7597 8.33723 64.6765 10.5855 64.6765 13.2084Z"
          fill="currentColor"
        />
        <path
          d="M15.3119 14.4637V4.32788H18.7044V14.4637C18.7044 16.1311 19.2912 17.5738 20.4831 18.7541C21.675 19.9344 23.087 20.5339 24.7191 20.5339C26.3878 20.5339 27.7998 19.9344 28.9734 18.7541C30.1469 17.5738 30.7338 16.1311 30.7338 14.4637V4.32788H34.1262V14.4637C34.1262 17.0867 33.2093 19.3349 31.3573 21.1897C29.5051 23.0633 27.2863 24 24.7007 24C22.1152 24 19.8963 23.0633 18.0625 21.1897C16.2288 19.3162 15.3119 17.0867 15.3119 14.4637Z"
          fill="currentColor"
        />
        <path
          d="M86.37 4.32787V7.83139H76.5044V13.452C76.5044 15.5691 77.4212 17.274 79.255 18.5481L79.2733 18.5668C80.5386 19.4286 82.0423 19.8033 83.5643 19.8033H86.37V23.4005H82.7208C80.0252 23.4005 77.7146 22.4637 75.8076 20.5152C73.8821 18.5668 72.9286 16.2436 72.9286 13.5269V0.468384H76.5227V4.32787H86.37Z"
          fill="currentColor"
        />
        <path
          d="M70.5253 5.03979H67.0595V23.4192H70.5253V5.03979Z"
          fill="currentColor"
        />
        <path
          d="M45.4051 3.67212C42.8195 3.67212 40.6007 4.60889 38.7486 6.48242C36.8965 8.35596 35.9797 10.6042 35.9797 13.2084V23.4005H39.3721V13.2084C39.3721 11.541 39.9589 10.0983 41.1324 8.91803C42.3061 7.7377 43.7181 7.13816 45.3867 7.13816H45.8636V3.67212H45.4051Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6805_17004">
          <rect width="87" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  ),
  tv: (props: IconProps) => (
    <svg viewBox="0 0 14 14" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333344 2.00033C0.333344 1.26395 0.930297 0.666992 1.66668 0.666992H12.3333C13.0697 0.666992 13.6667 1.26395 13.6667 2.00033V10.0003C13.6667 10.7366 13.0699 11.3337 12.3334 11.3337H11.6667C11.2985 11.3337 11 11.0352 11 10.667C11 10.2988 11.2985 10.0003 11.6667 10.0003H12.3333V2.00033L1.66668 2.00033V10.0003H5.66668C6.03487 10.0003 6.33334 10.2988 6.33334 10.667C6.33334 11.0352 6.03487 11.3337 5.66668 11.3337H1.66654C0.929986 11.3337 0.333344 10.7365 0.333344 10.0003V2.00033ZM7.66668 10.667C7.66668 10.2988 7.96515 10.0003 8.33334 10.0003H9.00001C9.3682 10.0003 9.66668 10.2988 9.66668 10.667C9.66668 11.0352 9.3682 11.3337 9.00001 11.3337H8.33334C7.96515 11.3337 7.66668 11.0352 7.66668 10.667Z"
        fill="currentColor"
      />
      <path
        d="M6.55374 4.81699H5.40574V8.66699H4.32074V4.81699H3.16574V3.94899H6.55374V4.81699Z"
        fill="currentColor"
      />
      <path
        d="M9.65436 8.66699H8.46436L6.89636 3.94899H8.05836L8.62536 5.83199C8.72802 6.16799 8.81669 6.49233 8.89136 6.80499C8.96602 7.11299 9.01502 7.33699 9.03836 7.47699L9.07336 7.69399C9.14802 7.20866 9.29969 6.58799 9.52836 5.83199L10.0954 3.94899H11.2434L9.65436 8.66699Z"
        fill="currentColor"
      />
    </svg>
  ),
  user: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.56574 15.442C5.55765 14.5121 6.88994 14 8.26667 14H15.7333C17.1101 14 18.4424 14.5121 19.4343 15.442C20.4284 16.374 21 17.6523 21 19V21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21V19C19 18.2259 18.6726 17.4694 18.0664 16.9011C17.458 16.3307 16.6198 16 15.7333 16H8.26667C7.38022 16 6.54202 16.3307 5.93362 16.9011C5.3274 17.4694 5 18.2259 5 19V21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21V19C3 17.6523 3.57164 16.374 4.56574 15.442Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4ZM7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7Z"
        fill="currentColor"
      />
    </svg>
  ),
  users: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
      {...getIconSizeProps(12, 12, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.62984 7.75832C2.04131 7.28354 2.61604 7 3.23333 7H6.26667C6.88396 7 7.45869 7.28354 7.87016 7.75832C8.27969 8.23085 8.5 8.85823 8.5 9.5V10.5C8.5 10.7761 8.27614 11 8 11C7.72386 11 7.5 10.7761 7.5 10.5V9.5C7.5 9.0809 7.35507 8.69087 7.11447 8.41325C6.87583 8.13789 6.56879 8 6.26667 8H3.23333C2.93121 8 2.62417 8.13789 2.38553 8.41325C2.14493 8.69087 2 9.0809 2 9.5V10.5C2 10.7761 1.77614 11 1.5 11C1.22386 11 1 10.7761 1 10.5V9.5C1 8.85823 1.22031 8.23085 1.62984 7.75832Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 2C3.92157 2 3.25 2.67157 3.25 3.5C3.25 4.32843 3.92157 5 4.75 5C5.57843 5 6.25 4.32843 6.25 3.5C6.25 2.67157 5.57843 2 4.75 2ZM2.25 3.5C2.25 2.11929 3.36929 1 4.75 1C6.13071 1 7.25 2.11929 7.25 3.5C7.25 4.88071 6.13071 6 4.75 6C3.36929 6 2.25 4.88071 2.25 3.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51588 7.43849C8.58492 7.17112 8.85764 7.01034 9.12501 7.07938C9.55374 7.19009 9.94929 7.41376 10.2678 7.73223C10.7366 8.20108 11 8.83696 11 9.5V10.5C11 10.7761 10.7761 11 10.5 11C10.2239 11 10 10.7761 10 10.5V9.5C10 9.10218 9.84196 8.72064 9.56066 8.43934C9.36959 8.24827 9.13229 8.11407 8.87499 8.04762C8.60761 7.97858 8.44684 7.70586 8.51588 7.43849Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53653 1.45777C7.64014 1.2018 7.93164 1.0783 8.18761 1.18191C9.10288 1.5524 9.75 2.45003 9.75 3.5C9.75 4.54997 9.10288 5.44759 8.18761 5.81809C7.93164 5.9217 7.64014 5.79819 7.53653 5.54223C7.43292 5.28626 7.55643 4.99476 7.81239 4.89115C8.36303 4.66826 8.75 4.12872 8.75 3.5C8.75 2.87128 8.36303 2.33174 7.81239 2.10885C7.55643 2.00523 7.43292 1.71374 7.53653 1.45777Z"
        fill="currentColor"
      />
    </svg>
  ),
  vectorRight: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4349 16.9142C14.0444 16.5237 14.0444 15.8905 14.4349 15.5L17.9853 11.9497L14.4497 8.41414C14.0592 8.02361 14.0592 7.39045 14.4497 6.99992C14.8403 6.6094 15.4734 6.6094 15.864 6.99992L20.1066 11.2426C20.4971 11.6331 20.4971 12.2663 20.1066 12.6568L15.8492 16.9142C15.4586 17.3047 14.8255 17.3047 14.4349 16.9142Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11.9496C3 11.3974 3.44771 10.9496 4 10.9496L19.3995 10.9496C19.9518 10.9496 20.3995 11.3974 20.3995 11.9496C20.3995 12.5019 19.9518 12.9496 19.3995 12.9496L4 12.9496C3.44772 12.9496 3 12.5019 3 11.9496Z"
        fill="currentColor"
      />
    </svg>
  ),
  wifi: (props: IconProps) => (
    <svg viewBox="0 0 14 14" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        d="M8.45951 10.3548C8.45951 11.178 7.7922 11.8453 6.96902 11.8453C6.14585 11.8453 5.47853 11.178 5.47853 10.3548C5.47853 9.53166 6.14585 8.86435 6.96902 8.86435C7.7922 8.86435 8.45951 9.53166 8.45951 10.3548Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.96902 7.29528C6.13989 7.29528 5.37449 7.55973 4.74998 8.00898C4.45109 8.22399 4.0345 8.156 3.81948 7.85711C3.60447 7.55822 3.67247 7.14162 3.97136 6.92661C4.81515 6.31961 5.8513 5.96195 6.96902 5.96195C8.08674 5.96195 9.12289 6.31961 9.96669 6.92661C10.2656 7.14162 10.3336 7.55822 10.1186 7.85711C9.90355 8.156 9.48695 8.22399 9.18806 8.00898C8.56355 7.55973 7.79815 7.29528 6.96902 7.29528Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.96903 4.31431C5.49919 4.31431 4.14029 4.78087 3.02978 5.57406C2.73017 5.78806 2.3138 5.71866 2.0998 5.41905C1.8858 5.11944 1.9552 4.70307 2.25481 4.48907C3.58397 3.53971 5.21224 2.98098 6.96903 2.98098C8.72582 2.98098 10.3541 3.53971 11.6833 4.48907C11.9829 4.70307 12.0523 5.11944 11.8383 5.41905C11.6243 5.71866 11.2079 5.78806 10.9083 5.57406C9.79777 4.78087 8.43888 4.31431 6.96903 4.31431Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.96901 1.33333C4.8648 1.33333 2.91782 1.998 1.32399 3.12891C1.02371 3.34197 0.607565 3.27127 0.394501 2.97099C0.181437 2.67071 0.252139 2.25456 0.552419 2.0415C2.36431 0.755872 4.57938 0 6.96901 0C9.35864 0 11.5737 0.755872 13.3856 2.0415C13.6859 2.25456 13.7566 2.67071 13.5435 2.97099C13.3305 3.27127 12.9143 3.34197 12.614 3.12891C11.0202 1.998 9.07321 1.33333 6.96901 1.33333Z"
        fill="currentColor"
      />
    </svg>
  ),
  xSmall: (props: IconProps) => (
    <svg viewBox="0 0 24 24" fill="none" {...getIconSizeProps(24, 24, props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 7.29289C7.68342 6.90237 8.31658 6.90237 8.70711 7.29289L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L7.29289 8.70711C6.90237 8.31658 6.90237 7.68342 7.29289 7.29289Z"
        fill="currentColor"
      />
    </svg>
  ),
};
