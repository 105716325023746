import React from 'react';
import { cn } from '@/utils/cn';

interface DividerProps {
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  grow?: boolean;
}

const Divider: React.FC<DividerProps> = ({
  className,
  orientation = 'horizontal',
  grow = false,
}) => {
  return (
    <div
      className={cn(
        orientation === 'horizontal' ? 'border-t' : 'h-full border-l',
        grow ? 'flex-grow' : 'flex-shrink-0',
        className
      )}
    />
  );
};

export default Divider;
