import { type FC, useState } from 'react';
import { useCallback } from 'react';
import { useFormContext, type UseFormReturn } from 'react-hook-form';
import { useAvailableAncillaries } from '@/hooks/useAvailableAncillaries';
import type { AncillaryValues, PassengerValues } from '@/utils/zod/schema';
import { Typography } from '@/components/primitives/Typography';
import { FormControl, FormItem } from '@/components/primitives/Form';
import ToggleableRow from '@/components/purchase/checkout/ToggleableRow';
import { TransText } from '@/i18n/trans/text';
import type { AdditionalOfferItem } from '@/types/offer';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';
import PassengerOptions from '@/components/purchase/checkout/ancillaries/ancillary-modal/PassengerOptions';
import ShowMoreButton from '@/components/ShowMoreButton';
import { Checkbox } from '@/components/primitives/Checkbox';
import { useTranslation } from 'react-i18next';

interface AncillaryOptionsLegProps {
  passengerListForm: UseFormReturn<{
    passengers: Array<PassengerValues>;
  }>;
}

const AncillaryOptions: FC<AncillaryOptionsLegProps> = ({
  passengerListForm,
}) => {
  const { t } = useTranslation();
  const form = useFormContext<AncillaryValues>();
  const selectedLegId = form.watch('legId');
  const availableAncillaries = useAvailableAncillaries(selectedLegId);
  const selectedAncillaries = form.watch('selectedAncillaries');
  const currency = useSelector(currencySelector);
  const [renderAllAncillaries, setRenderAllAncillaries] = useState(
    selectedAncillaries.length > 5
  );

  const handleAncillaryToggle = useCallback(
    (checked: boolean, ancillary: AdditionalOfferItem, index: number) => {
      if (!ancillary.id) return;

      if (checked) {
        form.setValue(`selectedAncillaries.${index}`, {
          id: ancillary.id,
          additionalOfferId: ancillary.additionalOfferId,
          bookedOfferId: ancillary.bookedOfferId,
          amount: ancillary.price?.amount || 0,
          passengersExternalReferences: [],
        });
      } else {
        const filteredAncillaries = selectedAncillaries.filter(
          (selected) => selected?.id !== ancillary.id
        );
        form.setValue('selectedAncillaries', filteredAncillaries);
      }
    },
    [form, selectedAncillaries]
  );

  const cleanUpHiddenAncillariesWithoutAssignedPassengers = () => {
    const cleanedAncillaries = selectedAncillaries
      .slice(5)
      .filter(
        (selectedAncillary) =>
          selectedAncillary?.passengersExternalReferences.length !== 0
      );
    form.setValue('selectedAncillaries', [
      ...selectedAncillaries.slice(0, 5),
      ...cleanedAncillaries,
    ]);
  };

  const prepareAncillariesList = useCallback(() => {
    if (renderAllAncillaries) {
      return availableAncillaries;
    }

    const alwaysShownAncillaries = availableAncillaries.slice(0, 5);
    const shownOnlyWhenSelectedAncillaries = availableAncillaries.slice(5);

    const selectedAncillariesIds = selectedAncillaries.map(
      (ancillary) => ancillary?.id
    );

    return [
      ...alwaysShownAncillaries,
      ...shownOnlyWhenSelectedAncillaries.filter((ancillary) =>
        selectedAncillariesIds.includes(ancillary.id!)
      ),
    ];
  }, [availableAncillaries, renderAllAncillaries, selectedAncillaries]);

  const ancillaries = prepareAncillariesList();

  return (
    <div className="flex flex-col gap-2">
      {!!ancillaries.length && (
        <>
          {ancillaries.map((ancillary, index) => {
            const isActive = selectedAncillaries.some(
              (selectedAncillary) => selectedAncillary?.id === ancillary.id
            );

            return (
              <FormItem key={ancillary.id}>
                <FormControl
                  hideLabel
                  label={<TransText i18nKey="selectAncillary" />}
                >
                  <div className="flex flex-col gap-2">
                    <ToggleableRow
                      isActive={isActive}
                      onClick={() =>
                        handleAncillaryToggle(!isActive, ancillary, index)
                      }
                    >
                      <div className="flex w-full items-center gap-2">
                        <Checkbox
                          checked={isActive}
                          className="h-4 w-4 flex-shrink-0"
                          aria-label={t(
                            'text.selectAncillary',
                            'Select ancillary'
                          )}
                          onCheckedChange={(checked) =>
                            handleAncillaryToggle(
                              checked === true,
                              ancillary,
                              index
                            )
                          }
                        />
                        <div className="flex w-full justify-between">
                          <Typography variant="body1-bold">
                            {ancillary.products?.[0]?.description}
                          </Typography>
                          <Typography
                            variant="body2-bold"
                            className="text-primary"
                          >
                            {ancillary.price?.amount}
                            {currency.symbol}
                          </Typography>
                        </div>
                      </div>
                    </ToggleableRow>
                    {isActive ? (
                      <PassengerOptions
                        ancillaryId={ancillary.id!}
                        passengerListForm={passengerListForm}
                        ancillaryListForm={form}
                        renderAllAncillaries={renderAllAncillaries}
                      />
                    ) : null}
                  </div>
                </FormControl>
              </FormItem>
            );
          })}
          {availableAncillaries.length > 5 && (
            <ShowMoreButton
              expanded={renderAllAncillaries}
              onClick={() => {
                if (renderAllAncillaries) {
                  cleanUpHiddenAncillariesWithoutAssignedPassengers();
                }
                setRenderAllAncillaries((current) => !current);
              }}
              labelWhenExpanded={<TransText i18nKey="showLess" />}
              labelWhenCompressed={<TransText i18nKey="showMore" />}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AncillaryOptions;
