import type { FC, ReactNode, MouseEvent } from 'react';
import { cn } from '@/utils/cn';

interface ToggleableRowProps {
  isActive: boolean;
  children: ReactNode;
  className?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const ToggleableRow: FC<ToggleableRowProps> = ({
  isActive,
  children,
  className,
  onClick,
}) => {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    // Don't trigger row click if clicking on a checkbox or its label
    if (
      (e.target as HTMLElement).closest('input[type="checkbox"]') ||
      (e.target as HTMLElement).closest('label')
    ) {
      return;
    }
    onClick?.(e);
  };

  return (
    <div
      className={cn(
        'flex items-center gap-2 rounded-md border border-neutral-light p-3',
        {
          ['border-primary bg-primary-light']: isActive,
          ['hover:cursor-pointer hover:bg-primary-pale']: !isActive || onClick,
        },
        className
      )}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default ToggleableRow;
