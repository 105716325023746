import type { LegDTO, AdmissionDTO, BookingDTO } from '@/types/dto';

const useBookingAdmissionsByLegId = (
  booking: BookingDTO | null
): Record<NonNullable<LegDTO['id']>, Array<AdmissionDTO>> => {
  return (
    booking?.bookedTrips?.reduce(
      (acc, { bookedOffers }) => {
        bookedOffers
          ?.flatMap(({ admissions }) => admissions || [])
          .forEach((admission) => {
            admission.coveredLegIds?.forEach(
              (legId) => (acc[legId] = [...(acc[legId] ?? []), admission])
            );
          });

        return acc;
      },
      {} as Record<NonNullable<LegDTO['id']>, Array<AdmissionDTO>>
    ) || {}
  );
};

export default useBookingAdmissionsByLegId;
