import getApiInstance from '@/api';
import type { BookingOfferDTO } from '@/types/dto';
import type { SearchFormValues } from '@turnit-ride-ui/webshop-search-widget/widget';
import download from 'downloadjs';
import i18n from '@/i18n';

export const downloadBookingTickets = async (bookingId: string) => {
  const api = (await getApiInstance()).agentApi;

  const response = await api.Documents_GetBookingTickets(
    {
      includeAll: true,
      bookingId,
    },
    {},
    {
      responseType: 'blob',
      headers: {
        'Accept-Language': i18n.language,
      },
    }
  );

  download(response.data, 'ticket', 'pdf');
};

export const downloadTicketDocumentByType = async (
  fulfillmentId: string,
  documentType: 'PKPASS' | 'TICKET'
) => {
  const api = (await getApiInstance()).agentApi;
  const [filename, mimeType] =
    documentType === 'PKPASS'
      ? [`ticket-${fulfillmentId}.pkpass`, 'application/vnd.apple.pkpass']
      : ['ticket.pdf', 'application/pdf'];

  const response = await api.Documents_GetDocument(
    {
      fulfillmentId,
      documentType,
    },
    {},
    {
      responseType: 'blob',
      headers: {
        'Accept-Language': i18n.language,
      },
    }
  );

  if (response.data instanceof Blob && response.data.size > 0) {
    download(response.data, filename, mimeType);
  } else {
    throw new Error('Empty or invalid document received');
  }
};

export const sendPurchaseConfirmation = async (
  bookingId: string,
  emailsOverride: Array<string>
) => {
  const api = (await getApiInstance()).agentApi;
  return (
    await api.Notifications_SendPurchaseConfirmation(null, {
      bookingId,
      emailsOverride,
      includeTickets: true,
    })
  ).data;
};

export const createBooking = async (
  offers: Array<BookingOfferDTO>,
  passengers: SearchFormValues['passengers']
) => {
  const api = (await getApiInstance()).agentApi;

  return (
    await api.Bookings_CreateBooking(null, {
      offers: [offers[0], ...offers.slice(1)],
      passengerSpecifications: passengers.map(
        ({ externalReference, ...rest }) => ({
          ...rest,
          externalReference: externalReference ?? '',
        })
      ),
    })
  ).data;
};

export const getConsents = async () => {
  const api = (await getApiInstance()).agentApi;

  return (await api.Consents_GetConsents()).data;
};
