import { createSelector, selector } from '@/store/utils';

// BOOKING
const selectPurchaseFlowBooking = selector((state) => state.purchase.booking);
export const purchaseFlowBookingSelector = createSelector(
  selectPurchaseFlowBooking
);

const selectPurchaseFlowBookingConfirmableUntil = selector(
  (state) => state.purchase.booking?.confirmableUntil
);
export const purchaseFlowBookingConfirmableUntilSelector = createSelector(
  selectPurchaseFlowBookingConfirmableUntil
);

const selectPurchaseFlowBookingPassengers = selector(
  (state) => state.purchase.booking?.passengers
);
export const purchaseFlowBookingPassengersSelector = createSelector(
  selectPurchaseFlowBookingPassengers
);

const selectPurchaseFlowBookingId = selector(
  (state) => state.purchase.booking?.id
);
export const purchaseFlowBookingIdSelector = createSelector(
  selectPurchaseFlowBookingId
);

// STEPPER
const selectActiveStep = selector((state) => state.purchase.activeStep);
export const activeStepSelector = createSelector(selectActiveStep);

// SEARCH FORM
const selectSearchFormValues = selector(
  (state) => state.purchase.searchFormValues
);
export const searchFormValuesSelector = createSelector(selectSearchFormValues);

// OUTBOUND

const selectOutboundData = selector((state) => state.purchase.outbound);
export const outboundDataSelector = createSelector(selectOutboundData);

const selectSelectedOutboundOfferMap = selector(
  (state) => state.purchase.outbound.selectedOfferMapByLegId
);
export const selectedOutboundOfferMapSelector = createSelector(
  selectSelectedOutboundOfferMap
);

const selectOutboundAdditionalOfferParts = selector(
  (state) => state.purchase.outbound.additionalOffers
);
export const outboundAdditionalOfferPartsSelector = createSelector(
  selectOutboundAdditionalOfferParts
);

// INBOUND

const selectInboundData = selector((state) => state.purchase.inbound);
export const inboundDataSelector = createSelector(selectInboundData);

const selectSelectedInboundOfferMap = selector(
  (state) => state.purchase.inbound.selectedOfferMapByLegId
);
export const selectedInboundOfferMapSelector = createSelector(
  selectSelectedInboundOfferMap
);

const selectInboundAdditionalOfferParts = selector(
  (state) => state.purchase.inbound.additionalOffers
);
export const inboundAdditionalOfferPartsSelector = createSelector(
  selectInboundAdditionalOfferParts
);

// NON-TRIP

const selectNonTripOffers = selector((state) => state.purchase.nonTripOffers);
export const nonTripOffersSelector = createSelector(selectNonTripOffers);
