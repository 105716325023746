import type { FC, ReactNode } from 'react';
import Divider from '@/components/primitives/Divider';

type RegularSearchWidgetProps = {
  children: ReactNode;
};

export const RegularSearchWidget: FC<RegularSearchWidgetProps> = ({
  children,
}) => (
  <>
    <div className="mx-auto p-5 laptop:max-w-tablet desktop:max-w-laptop">
      {children}
    </div>
    <Divider className="border-neutral" />
  </>
);
