import { useSelector } from '@/store/utils';
import { manageFlowBookingSelector } from '@/features/manage/manageSelectors';
import { BookingDetails } from '@/components/manage/BookingDetails';
import { FindBooking } from '@/components/manage/FindBooking';

export const Manage = () => {
  const booking = useSelector(manageFlowBookingSelector);

  return booking ? <BookingDetails /> : <FindBooking />;
};
